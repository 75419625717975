import { type ReactNode, useCallback, useMemo } from 'react'
import type { AccessRule, SeatingAreaToTables } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Checkbox, FieldErrors, Label, MultiSelectTree, type FlattenTreeNode, type TreeNode } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { AccessRuleTooltip, useAccessRuleContext } from '../../shared'
import { SeatingAreasLocales } from '../SeatingAreas.locales'
import { SeatingAreasTestId } from '../SeatingAreas.testIds'
import { SeatingAreaIntegrationFields } from './SeatingAreaIntegrationFields'
import type { ShiftSeatingAreas } from './ShiftSeatingAreas'
import type { SeatingAreasForm } from '../SeatingAreas.zod'

export interface SeatingAreasFieldsProps {
  field: Field<SeatingAreasForm>
  shiftSeatingAreas: ShiftSeatingAreas[]
  conflict: ReactNode
  disableEdit?: boolean
}

export function SeatingAreasFields({ field, shiftSeatingAreas, conflict, disableEdit }: SeatingAreasFieldsProps) {
  const { seatingAreaToTables, accessRule } = useAccessRuleContext()
  const { isGoogleBookingEnabled, isTheforkIntegrationEnabled } = useAppContext().venueSettings
  const { formatMessage } = useLocales()

  const selection = useWatch(field.prop('selection'))
  const treatAsBlocked = field.prop('treatAsBlocked')

  const treeData = useMemo(
    () => getMultiSelectOptions(seatingAreaToTables, shiftSeatingAreas, accessRule),
    [seatingAreaToTables, shiftSeatingAreas, accessRule]
  )

  const optionWarning = useCallback(
    (option: FlattenTreeNode<TreeValue>) => {
      const node = option.value
      const { isBookable } = node.value
      const checked = node.checked || (option.meta.partial && !node.expanded)
      return checked && !isBookable ? <>{conflict}</> : null
    },
    [conflict]
  )

  return (
    <>
      <VStack spacing="m">
        <VStack spacing="sm">
          <VStack spacing="s">
            <Label
              noMargin
              primary={
                <HStack spacing="xs">
                  <Text>{formatMessage(SeatingAreasLocales.multiSelectLabel)}</Text>
                  <HelpTooltip />
                </HStack>
              }
            />
            <MultiSelectTree
              disabled={disableEdit}
              data-test={SeatingAreasTestId.seatingAreasMultiSelect}
              id={SeatingAreasTestId.seatingAreasMultiSelect}
              placeholder={formatMessage(SeatingAreasLocales.multiSelectPlaceholder)}
              treeData={treeData}
              field={field.prop('selection')}
              extraNodeContent={optionWarning}
              onChange={(_, selection) => {
                if (selection.length === 0) {
                  treatAsBlocked.set(false)
                }
              }}
            />
          </VStack>
          <HStack spacing="xs">
            <Checkbox
              data-test={SeatingAreasTestId.treatAsBlockedCheckbox}
              field={treatAsBlocked}
              disabled={selection?.length === 0 || disableEdit}
            >
              {formatMessage(SeatingAreasLocales.checkboxLabel)}
            </Checkbox>
            <AccessRuleTooltip data-test={SeatingAreasTestId.treatAsBlockedTooltip}>
              <Text color="lightFont">{formatMessage(SeatingAreasLocales.treatAsBlockedTooltip)}</Text>
              <Anchor href="https://help.sevenrooms.com/hc/en-us/articles/360025628471-Access-Rules">
                {formatMessage(SeatingAreasLocales.moreAboutThisSetting)}
              </Anchor>
            </AccessRuleTooltip>
          </HStack>
        </VStack>
        {(isGoogleBookingEnabled || isTheforkIntegrationEnabled) && (
          <SeatingAreaIntegrationFields
            theForkField={field.prop('theForkSeatingArea')}
            googleReserveField={field.prop('googleReserveSeatingArea')}
          />
        )}
      </VStack>

      <FieldErrors data-test={SeatingAreasTestId.seatingAreasErrors} field={field} />
    </>
  )
}

function HelpTooltip() {
  const { formatMessage } = useLocales()

  const messageProps = {
    strong: (chunks: string[]) => (
      <Text color="lightFont" fontWeight="bold">
        {chunks}
      </Text>
    ),
  }

  return (
    <AccessRuleTooltip data-test={SeatingAreasTestId.helpTooltip}>
      <Text color="lightFont" fontWeight="bold">
        {formatMessage(SeatingAreasLocales.helpTooltipImportant)}
      </Text>
      <Text color="lightFont">{formatMessage(SeatingAreasLocales.helpTooltipSingleSeatingArea, messageProps)}</Text>
      <Text color="lightFont">{formatMessage(SeatingAreasLocales.helpTooltipMultipleSeatingArea, messageProps)}</Text>
    </AccessRuleTooltip>
  )
}

export type TreeValue = SeatingAreasForm['selection'][0]['value']
export function getMultiSelectOptions(
  seatingAreaToTables: SeatingAreaToTables[],
  shiftToSeatingAreas: ShiftSeatingAreas[],
  accessRule?: AccessRule
): TreeNode<TreeValue>[] {
  const tree: TreeNode<TreeValue>[] = []
  // duplicate!!
  const shiftSeatingAreas = shiftToSeatingAreas.map(({ seatingAreas, tables }) => ({
    seatingAreas: seatingAreas.map(({ id }) => id),
    tables: tables.map(({ id }) => id),
  }))

  for (const seatingArea of seatingAreaToTables) {
    const children: TreeNode<TreeValue>[] = []
    const checked = accessRule?.seatingAreaIDS.includes(seatingArea.id ?? '')

    for (const table of seatingArea.tables) {
      const tableChecked = accessRule?.tableIDS.includes(table.id) || checked
      children.push({
        id: table.id,
        label: table.itemCode,
        value: {
          isTable: true,
          isBookable: shiftSeatingAreas.every(({ tables }) => tables.length === 0 || tables.includes(table.id)),
        },
        checked: tableChecked,
      })
    }

    tree.push({
      id: seatingArea.id,
      label: seatingArea.name ?? 'No name',
      value: {
        isTable: false,
        isBookable: shiftSeatingAreas.every(({ seatingAreas }) => seatingAreas.length === 0 || seatingAreas.includes(seatingArea.id)),
      },
      checked,
      expanded: !checked && children.some(({ checked }) => checked),
      children,
    })
  }

  return tree
}
