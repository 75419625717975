import constate from 'constate'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { ALL_WEEK_DAYS, isDollarAmountType } from '@sevenrooms/core/api'
import type {
  Event,
  PlatformApplyType,
  PromoCode,
  PromoComponent,
  PromoRangeApplyType,
  PromoType,
  UsageType,
} from '@sevenrooms/core/domain'
import { useDayOfWeekPicker, type DayOfWeekPickerChoiceProps, type TreeNode } from '@sevenrooms/core/ui-kit/form'
import { usePromoCodeResourcesContext } from './usePromoCodeResourcesContext'

const isNotEmpty = (value?: null | string | number) => !_.isEmpty(`${value || ''}`.trim())
const hasSelected = (nodes: TreeNode[]) => nodes.some(node => node.checked)

function usePromoCode({ promoCode, events }: { promoCode?: PromoCode; events?: Event[] }) {
  const { widgetChoices, usageChoices, codeTypeChoices, creditCardChoices, allEventsNode } = usePromoCodeResourcesContext()
  const [containsDirtyChanges, setContainsDirtyChanges] = useState<boolean>(false)
  // General Section
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string | null>('')
  const [code, setCode] = useState<string>('')
  const [widgets, setWidgets] = useState<TreeNode<PlatformApplyType>[]>(widgetChoices)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [codeType, setCodeType] = useState(codeTypeChoices[0]!.id)
  const [usage, setUsage] = useState<UsageType>('UNLIMITED')
  const [limit, setLimit] = useState<number | null>(null)
  const [uses, setUses] = useState<number>(0)
  // Promo type section
  const [promoType, setPromoType] = useState<PromoType | undefined>(undefined)
  const [promoValue, setPromoValue] = useState<number>(0)
  const [requireMinimumSubtotal, setRequireMinimumSubtotal] = useState<boolean>(false)
  const [minimumSubtotalAmount, setMinimumSubtotalAmount] = useState<number | null>(null)
  const [upToFixedAmount, setUpToFixedAmount] = useState<boolean>(false)
  const [fixedAmountCap, setFixedAmountCap] = useState<number | null>(null)
  // Lifespan section
  const [selectedLifespan, setSelectedLifespan] = useState<PromoRangeApplyType>('NO_EXPIRATION')
  const [startDateTime, setStartDateTime] = useState<Date | null>(null)
  const [endDateTime, setEndDateTime] = useState<Date | 'infinite' | null>(null)
  const { selectedVals: selectedDates, onChange: setSelectedDates } = useDayOfWeekPicker(ALL_WEEK_DAYS)
  // Additional settings section
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [selectedCreditCard, setSelectedCreditCard] = useState(creditCardChoices[0]!.id)
  const [selectedPromoComponent, setSelectedPromoComponent] = useState<PromoComponent>('ALL')
  const [eventsChoices, setEventsChoices] = useState<TreeNode<string>>(allEventsNode)

  const [isNameValid, setNameValid] = useState<boolean>(true)
  const validateName = (value: string) => isNotEmpty(value)
  const [isCodeValid, setCodeValid] = useState<boolean>(true)
  const validateCode = (value: string) => isNotEmpty(value)
  const [isWidgetsValid, setWidgetsValid] = useState<boolean>(true)
  const validateWidgets = (nodes: TreeNode[]) => hasSelected(nodes)
  const [isPromoValueValid, setPromoValueValid] = useState<boolean>(true)
  const validatePromoValue = (value: number) => value > 0
  const [isFixedAmountCapValid, setFixedAmountCapValid] = useState<boolean>(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateFixedAmountCap = (value: number | null) => !upToFixedAmount || (value !== null && value > 0)
  const [isMinimumSubtotalAmountValid, setMinimumSubtotalAmountValid] = useState<boolean>(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateMinimumSubtotalAmount = (value: number | null) => !requireMinimumSubtotal || (value !== null && value > 0)
  const [isLimitValid, setLimitValid] = useState<boolean>(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateLimit = (value: number | null) => usage === 'UNLIMITED' || (value !== null && value >= uses)
  const [isStartDateTimeValid, setStartDateTimeValid] = useState<boolean>(true)
  const [isEndDateTimeValid, setEndDateTimeValid] = useState<boolean>(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateDateTimeField = (value?: Date | 'infinite' | null) => selectedLifespan === 'NO_EXPIRATION' || !_.isNil(value)

  const isSaveDisabled = !containsDirtyChanges || !promoType

  const validateForm = useCallback((): boolean => {
    const isNameValid = validateName(name)
    const isCodeValid = validateCode(code)
    const isPromoValid = validatePromoValue(promoValue)
    const isPromoFixedAmountCapValid = validateFixedAmountCap(fixedAmountCap)
    const isMinimumSubtotalAmountValid = validateMinimumSubtotalAmount(minimumSubtotalAmount)
    const isWidgetsValid = validateWidgets(widgets)
    const isLimitValid = validateLimit(limit)
    const isStartDateTimeValid = validateDateTimeField(startDateTime)
    const isEndDateTimeValid = validateDateTimeField(endDateTime)

    setNameValid(isNameValid)
    setCodeValid(isCodeValid)
    setWidgetsValid(isWidgetsValid)
    setPromoValueValid(isPromoValid)
    setFixedAmountCapValid(isPromoFixedAmountCapValid)
    setMinimumSubtotalAmountValid(isMinimumSubtotalAmountValid)
    setLimitValid(isLimitValid)
    setStartDateTimeValid(isStartDateTimeValid)
    setEndDateTimeValid(isEndDateTimeValid)
    return (
      isNameValid &&
      isCodeValid &&
      isPromoValid &&
      isMinimumSubtotalAmountValid &&
      isPromoFixedAmountCapValid &&
      isWidgetsValid &&
      isLimitValid &&
      isStartDateTimeValid &&
      isEndDateTimeValid
    )
  }, [
    code,
    endDateTime,
    fixedAmountCap,
    limit,
    minimumSubtotalAmount,
    name,
    promoValue,
    startDateTime,
    validateDateTimeField,
    validateFixedAmountCap,
    validateLimit,
    validateMinimumSubtotalAmount,
    widgets,
  ])

  useEffect(() => {
    if (promoCode) {
      setContainsDirtyChanges(false)
      // General Section
      setName(promoCode.name || promoCode.code)
      setDescription(promoCode.promoCodeDescription)
      setCode(promoCode.code)
      setCodeType(promoCode.codeType)
      setWidgets(
        widgetChoices.map((choice: TreeNode<PlatformApplyType>) => ({
          ...choice,
          checked: promoCode.platformApplyTypes.includes(choice.value),
        }))
      )
      // Promo type section
      setPromoValue(promoCode.promoValue)
      setPromoType(isDollarAmountType(promoCode.promoType) ? 'DOLLAR_DISCOUNT' : 'PERCENT_DISCOUNT')
      setRequireMinimumSubtotal(promoCode.minSubtotal !== null)
      setMinimumSubtotalAmount(promoCode.minSubtotal)
      setUpToFixedAmount(promoCode.promoType === 'PERCENT_DISCOUNT_WITH_CAP')
      setFixedAmountCap(promoCode.promoValueCap)
      // Lifespan section
      setSelectedLifespan(promoCode.promoRangeApplyType)
      setUsage(promoCode.limit !== null ? 'LIMITED' : 'UNLIMITED')
      setLimit(promoCode.limit)
      setUses(promoCode.uses)
      setStartDateTime(promoCode.startDateTime)
      setEndDateTime(promoCode.expires)
      ALL_WEEK_DAYS.forEach(dayIndex => {
        const isSelected = promoCode.applicableDaysOfWeek.some(dayOfWeek => dayOfWeek === dayIndex)
        setSelectedDates({ value: dayIndex, label: '' }, isSelected)
      })
      // Additional settings section
      setSelectedCreditCard(promoCode.creditCardType)
      setSelectedPromoComponent(promoCode.promoComponent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeTypeChoices, creditCardChoices, promoCode, usageChoices, widgetChoices])

  useEffect(() => {
    if (events) {
      setEventsChoices({
        ...allEventsNode,
        checked: !events.length || promoCode?.applicableEntityIds.length === events.length,
        children: events.map(event => ({
          id: event.id,
          value: event.id,
          label: event.name,
          checked: promoCode?.applicableEntityIds.includes(event.id),
        })),
      })
    }
  }, [allEventsNode, events, promoCode])

  return {
    isSaveDisabled,
    validateForm,
    containsDirtyChanges,
    resetForm: () => {
      setContainsDirtyChanges(false)
    },
    name,
    setName: (value: string) => {
      setContainsDirtyChanges(true)
      setName(value)
      setNameValid(validateName(value))
    },
    isNameValid,
    description,
    setDescription: (value: string) => {
      setContainsDirtyChanges(true)
      setDescription(value)
    },
    code,
    setCode: (value: string) => {
      setContainsDirtyChanges(true)
      setCode(value)
      setCodeValid(validateCode(value))
    },
    isCodeValid,
    widgets,
    setWidgets: (selectedWidgets: TreeNode<string>[]) => {
      setContainsDirtyChanges(true)
      const widgets = widgetChoices.map((choice: TreeNode<PlatformApplyType>) => ({
        ...choice,
        checked: selectedWidgets.some(selectedWidget => selectedWidget.value === choice.value),
      }))
      setWidgetsValid(validateWidgets(widgets))
      setWidgets(widgets)
    },
    isWidgetsValid,
    selectedLifespan,
    setSelectedLifespan: (value: PromoRangeApplyType) => {
      setContainsDirtyChanges(true)
      setSelectedLifespan(value)
    },
    codeType,
    setCodeType: (value: string) => {
      setContainsDirtyChanges(true)
      setCodeType(value)
    },
    promoType,
    setPromoType: (value: PromoType) => {
      setContainsDirtyChanges(true)
      setPromoType(value)
      setPromoValue(0)
    },
    promoValue,
    isPromoValueValid,
    setPromoValue: (value: number | undefined) => {
      if (value === undefined) {
        return
      }

      setContainsDirtyChanges(true)
      setPromoValue(value)
      setPromoValueValid(validatePromoValue(value))
    },
    requireMinimumSubtotal,
    setRequireMinimumSubtotal: (value: boolean) => {
      setContainsDirtyChanges(true)
      setRequireMinimumSubtotal(value)
    },
    minimumSubtotalAmount,
    setMinimumSubtotalAmount: (value: number | undefined) => {
      if (value === undefined) {
        return
      }

      setContainsDirtyChanges(true)
      setMinimumSubtotalAmount(value)
      setMinimumSubtotalAmountValid(validateMinimumSubtotalAmount(value))
    },
    isMinimumSubtotalAmountValid,
    upToFixedAmount,
    setUpToFixedAmount: (value: boolean) => {
      setContainsDirtyChanges(true)
      setUpToFixedAmount(value)
      setPromoType(value ? 'PERCENT_DISCOUNT_WITH_CAP' : 'PERCENT_DISCOUNT')
    },
    fixedAmountCap,
    setFixedAmountCap: (value: number | undefined) => {
      if (value === undefined) {
        return
      }

      setContainsDirtyChanges(true)
      setFixedAmountCap(value)
      setFixedAmountCapValid(validateFixedAmountCap(value))
    },
    isFixedAmountCapValid,
    usage,
    setUsage: (value: UsageType) => {
      setContainsDirtyChanges(true)
      setUsage(value)
    },
    limit,
    uses,
    setLimit: (value: number | null) => {
      if (value === null) {
        return
      }

      setContainsDirtyChanges(true)
      setLimit(value)
      setLimitValid(validateLimit(value))
    },
    isLimitValid,
    startDateTime,
    setStartDateTime: (value: Date | null) => {
      setContainsDirtyChanges(true)
      setStartDateTime(value)
      setStartDateTimeValid(validateDateTimeField(value))
    },
    isStartDateValid: isStartDateTimeValid,
    endDateTime,
    setEndDateTime: (value: Date | 'infinite' | null) => {
      setContainsDirtyChanges(true)
      setEndDateTime(value)
      setEndDateTimeValid(validateDateTimeField(value))
    },
    isEndDateValid: isEndDateTimeValid,
    selectedDates,
    setSelectedDates: (choice: DayOfWeekPickerChoiceProps, checked: boolean) => {
      setContainsDirtyChanges(true)
      setSelectedDates(choice, checked)
    },
    selectedCreditCard,
    setSelectedCreditCard: (value: string) => {
      if (value !== selectedCreditCard) {
        setContainsDirtyChanges(true)
        setSelectedCreditCard(value)
      }
    },
    selectedPromoComponent,
    setSelectedPromoComponent: (value: PromoComponent) => {
      setContainsDirtyChanges(true)
      setSelectedPromoComponent(value)
    },
    eventsChoices,
    setSelectedEvents: (selectedEvents: TreeNode<string>[]) => {
      setContainsDirtyChanges(true)
      const selectedEvent = selectedEvents[0]
      const rootNode = { ...eventsChoices }
      if (!selectedEvent || selectedEvent.value === allEventsNode.value) {
        rootNode.checked = selectedEvent?.checked
        rootNode.children = eventsChoices?.children?.map((choice: TreeNode<string>) => ({
          ...choice,
          checked: selectedEvent?.checked,
        }))
      } else {
        rootNode.checked = selectedEvents.length === eventsChoices?.children?.length
        rootNode.children = eventsChoices?.children?.map((choice: TreeNode<string>) => ({
          ...choice,
          checked: rootNode.checked || selectedEvents.some(selectedEvent => selectedEvent.value === choice.value),
        }))
      }
      setEventsChoices(rootNode)
    },
    getDataForSave: () =>
      ({
        ...promoCode,
        code,
        name,
        promoCodeDescription: description,
        codeType,
        promoType,
        promoValue,
        minSubtotal: minimumSubtotalAmount,
        promoValueCap: fixedAmountCap,
        creditCardType: selectedCreditCard,
        applicableDaysOfWeek: selectedDates,
        applicableEntityIds: eventsChoices?.children?.filter(event => event.checked).map(event => event.value),
        promoComponent: selectedPromoComponent,
        promoRangeApplyType: selectedLifespan,
        platformApplyTypes: widgets.filter(widget => widget.checked).map(widget => widget.value),
        limit,
        expires: endDateTime,
        startDateTime,
      } as PromoCode),
  }
}

export const [PromoCodeContext, usePromoCodeContext] = constate(usePromoCode)
