import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  configurationLabel: {
    id: 'posiSettings.BsaeLocationSelectionCard.configurationLabel',
    defaultMessage: 'Configuration',
  },
  docLinkLabel: {
    id: 'posiSettings.BaseLocationSelectionCard.docLinkLabel',
    defaultMessage: 'View Docs',
  },
} as const)
