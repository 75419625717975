import { defineMessages } from '@sevenrooms/core/locales'

export const otherLanguagesTableMessages = defineMessages({
  otherLanguagesTableVenueDescriptionColumnLabel: {
    id: 'otherLanguagesTableVenueDescriptionColumnLabel',
    defaultMessage: 'Venue Description',
  },
  otherLanguagesTableWidgetTitleColumnLabel: {
    id: 'otherLanguagesTableWidgetTitleColumnLabel',
    defaultMessage: 'Widget Title',
  },
  otherLanguagesTableSpecialAttentionLabelColumnLabel: {
    id: 'otherLanguagesTableSpecialAttentionLabelColumnLabel',
    defaultMessage: 'Special Attention Message:',
  },
  otherLanguagesTableSpecialAttentionLabelColumnDescription: {
    id: 'otherLanguagesTableSpecialAttentionLabelColumnDescription',
    defaultMessage: 'Main Headline',
  },
  otherLanguagesTableSpecialAttentionBodyColumnLabel: {
    id: 'otherLanguagesTableSpecialAttentionBodyColumnLabel',
    defaultMessage: 'Special Attention Message:',
  },
  otherLanguagesTableSpecialAttentionBodyColumnDescription: {
    id: 'otherLanguagesTableSpecialAttentionBodyColumnDescription',
    defaultMessage: 'Pop-up Content',
  },
  otherLanguagesTablePEBannerHeadlineLabel: {
    id: 'otherLanguagesTablePEBannerHeadlineLabel',
    defaultMessage: 'Private Events Banner Headline',
  },
  otherLanguagesTablePEBannerDescriptionLabel: {
    id: 'otherLanguagesTablePEBannerHeadlineLabel',
    defaultMessage: 'Private Events Banner Copy',
  },
  otherLanguagesTablePEBannerButtonLabel: {
    id: 'otherLanguagesTablePEBannerButtonLabel',
    defaultMessage: 'Private Events Button Label',
  },
  otherLanguagesTableColumnPlaceholder: {
    id: 'otherLanguagesTableColumnPlaceholder',
    defaultMessage: 'Enter copy here',
  },
})
