import {
  PDRAmenitiesEnum,
  type PDRAmenitiesEnumOption,
  PDRRoomTypeEnum,
  type PDRRoomTypeEnumOption,
  PDRUnitOfMeasurementEnum,
  type PDRUnitOfMeasurementEnumOption,
} from '@sevenrooms/core/domain'

export const roomTypeOptions = Object.entries(PDRRoomTypeEnum).map(([roomId, label]) => ({
  id: roomId as PDRRoomTypeEnumOption,
  label,
}))

export const unitsOfMeasurementOptions = Object.entries(PDRUnitOfMeasurementEnum).map(([unitId, label]) => ({
  id: unitId as PDRUnitOfMeasurementEnumOption,
  label,
}))

export const amenitiesOptions = Object.entries(PDRAmenitiesEnum)
  .sort(([a], [b]) => a.localeCompare(b))
  .map(([amenitiesId, label]) => ({
    id: amenitiesId as PDRAmenitiesEnumOption,
    label,
  }))
