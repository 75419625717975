import { useLocales } from '@sevenrooms/core/locales'
import { getDateFNSLocale } from '@sevenrooms/core/timepiece'
import { Flex } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { Link } from '@sevenrooms/react-components/components/Link'
import { LocalizationProvider } from '@sevenrooms/react-components/components/LocalizationProvider'
import { ThemeProvider, vmsTheme } from '@sevenrooms/react-components/components/ThemeProvider'
import { AccessRulesActionButtons } from '../../components/ActionButtons/AccessRulesActionButtons'
import { ActionsBar } from '../../components/ActionsBar/ActionsBar'
import { AccessRuleSlideoutProvider } from '../../contexts/AccessRuleSlideoutProvider'
import { accessRulesSettingsMessages } from '../../locales'

export function AccessRulesSettings() {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()
  const { venueLocale } = useAppContext()
  const locale = getDateFNSLocale(venueLocale)

  if (!venue) {
    return null
  }

  return (
    <ThemeProvider theme={vmsTheme}>
      <LocalizationProvider adapterLocale={locale}>
        <AccessRuleSlideoutProvider>
          <SettingsPageMeta title={formatMessage(accessRulesSettingsMessages.title)} />
          <SettingsPageContent
            secondHeaderMaxWidth="100%"
            secondHeaderTextMaxWidth="90%"
            title={formatMessage(accessRulesSettingsMessages.title)}
            description={formatMessage(accessRulesSettingsMessages.subtitle, {
              a: (chunks: string[]) => (
                <Link
                  data-test="access-rules-settings-help"
                  href="https://help.sevenrooms.com/hc/en-us/categories/360004583192-Availability"
                >
                  {chunks}
                </Link>
              ),
            })}
            actions={<AccessRulesActionButtons />}
          >
            <Flex backgroundColor="primaryBackground" p="lm" width="100%" height="100%" display="flex" flexDirection="column">
              <ActionsBar />
            </Flex>
          </SettingsPageContent>
        </AccessRuleSlideoutProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}
