import { defineMessages } from '@sevenrooms/core/locales'

export const checkoutConfirmationMessages = defineMessages({
  extrasLabel: {
    id: 'extrasLabel',
    defaultMessage: 'Extras',
  },
  promoCodes: {
    id: 'promoCodes',
    defaultMessage: 'Promo Codes',
  },
  promoCodesName: {
    id: 'promoCodesName',
    defaultMessage: 'Promo Codes',
  },
  promoCodesDescription: {
    id: 'promoCodesDescription',
    defaultMessage: 'View, edit, and configure codes in {link}',
  },
  enableGiftCardRedemption: {
    id: 'enableGiftCardRedemption',
    defaultMessage: 'Enable Gift Card Redemption',
  },
  enableSocialMediaLogin: {
    id: 'enableSocialMediaLogin',
    defaultMessage: 'Ability to log in at Checkout via social media',
  },
  optInsAndLegalese: {
    id: 'optInsAndLegalese',
    defaultMessage: 'Opt-ins and Legalese',
  },
  customCheckoutPolicyOn: {
    id: 'customCheckoutPolicyOn',
    defaultMessage: 'Custom policy agreement',
  },
  mandatedAgeConsent: {
    id: 'mandatedAgeConsent',
    defaultMessage: 'Mandated age consent',
  },
  venueSmsMarketingOn: {
    id: 'mandatedAgeConsent',
    defaultMessage: 'Opt-in for Text Marketing Checkbox for Venue',
  },
  venueSpecificMarketingOn: {
    id: 'venueSpecificMarketingOn',
    defaultMessage: 'Opt-in for Email Marketing Checkbox for Venue',
  },
  venueGroupMarketingOn: {
    id: 'venueGroupMarketingOn',
    defaultMessage: 'Opt-in for Email Marketing Checkbox for Group',
  },
  tailoredCommunicationOn: {
    id: 'tailoredCommunicationOn',
    defaultMessage: 'Opt-in to receive tailored communications',
  },
  displayReservationSmsOptIn: {
    id: 'displayReservationSmsOptIn',
    defaultMessage: 'Opt-in for receiving SMS messages about reservations.',
  },
  recaptchaOn: {
    id: 'recaptchaOn',
    defaultMessage: 'reCAPTCHA',
  },
  clientInformation: {
    id: 'clientInformation',
    defaultMessage: 'Client Information',
  },
  birthdayType: {
    id: 'birthdayType',
    defaultMessage: 'Birthday input field at Checkout',
  },
  postalCode: {
    id: 'postalCode',
    defaultMessage: 'Postal Code input field at Checkout',
  },
  salutationType: {
    id: 'salutationType',
    defaultMessage: 'Salutation selection at Checkout',
  },
  enableDietaryRestrictionsConfirmation: {
    id: 'enableDietaryRestrictionsConfirmation',
    defaultMessage: 'Dietary Restrictions on Confirmation page',
  },
  requireDietaryTagGdprOptInConfirmation: {
    id: 'requireDietaryTagGdprOptInConfirmation',
    defaultMessage: 'Require active consent for processing Dietary Restrictions',
  },
  enableSpecialOccasionsConfirmation: {
    id: 'enableSpecialOccasions',
    defaultMessage: 'Special Occasion selection on Confirmation page',
  },
  clientTags: {
    id: 'clientTags',
    defaultMessage: 'Client Tags',
  },
  enableSpecialOccasionsDescrConfirmation: {
    id: 'enableSpecialOccasionsDescrConfirmation',
    defaultMessage: 'View and edit list of occasions in {link}',
  },
  enableAdditionalProfileInfoConfirmation: {
    id: 'enableAdditionalProfileInfoConfirmation',
    defaultMessage: 'Additional Guest Profile information on Confirmation page',
  },
  enableAdditionalProfileInfoDescConfirmation: {
    id: 'enableAdditionalProfileInfoDescConfirmation',
    defaultMessage: 'Provide the option to add a profile photo and additional details they want your venue to know',
  },
  checkedByDefault: {
    id: 'checkedByDefault',
    defaultMessage: 'Checked by default',
  },
  uncheckedByDefault: {
    id: 'uncheckedByDefault',
    defaultMessage: 'Unchecked by default',
  },
  required: {
    id: 'required',
    defaultMessage: 'Required',
  },
  optional: {
    id: 'optional',
    defaultMessage: 'Optional',
  },
  hidden: {
    id: 'hidden',
    defaultMessage: 'Hidden',
  },
  privacyPolicyNotification: {
    id: 'privacyPolicyNotification',
    defaultMessage: 'Privacy Policy notification',
  },
  privacyPolicyNotificationDescr: {
    id: 'privacyPolicyNotificationDescr',
    defaultMessage: 'The small print below the Submit button can include these customizations. Edit any other Policy in {link}',
  },
  privacyPolicyNotificationLink: {
    id: 'privacyPolicyNotificationLink',
    defaultMessage: 'Reservation Widget Guest-Facing Language',
  },
  customPrivacyPolicyLink: {
    id: 'customPrivacyPolicyLink',
    defaultMessage: 'Custom Privacy Policy link',
  },
  customPrivacyPolicyHolderName: {
    id: 'customPrivacyPolicyHolderName',
    defaultMessage: 'Custom Privacy Policy holder name (if link above is customized)',
  },
  customGDPRPolicyLink: {
    id: 'customGDPRPolicyLink',
    defaultMessage: 'Custom GDPR Policy link',
  },
  groupBookingPolicyOn: {
    id: 'groupBookingPolicyOn',
    defaultMessage: 'Group Booking policy agreement',
  },
})
