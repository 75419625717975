import { useMemo } from 'react'
import { type AccessRule, type AccessRuleAccessTime, AccessRuleAccessTimeEnum, type ShiftCategory } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useDateRangePickerForm, useDayOfWeekPickerForm } from '@sevenrooms/core/ui-kit/form'
import { generateTimeSlots } from '../shared/utils'
import { ScheduleLocales } from './Schedule.locales'

export type ScheduleForm = ZodSchema<typeof useScheduleForm>

export function useScheduleForm() {
  const { formatMessage } = useLocales()
  const dateRange = useDateRangePickerForm()
  const selectedDays = useDayOfWeekPickerForm()

  return useMemo(
    () =>
      z
        .object({
          accessTimeType: z.custom<AccessRuleAccessTime>(),
          dateRange,
          selectedDays,
          restrictToShifts: z.boolean(),
          shiftCategories: z.array(z.custom<ShiftCategory>()),
          specificTimes: z.array(z.string()).describe('Array of time part of ISO 8601 without timezone offset'),
          startTime: z.string().nullable().describe('Time part of ISO 8601 without timezone offset'),
          endTime: z.string().nullable().describe('Time part of ISO 8601 without timezone offset'),
        })
        .superRefine(({ accessTimeType, shiftCategories, specificTimes, startTime, endTime }, ctx) => {
          if (accessTimeType === AccessRuleAccessTimeEnum.ALL && shiftCategories.length === 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(ScheduleLocales.shiftSelectionRequired),
              path: ['shiftCategories'],
            })
          } else if (accessTimeType === AccessRuleAccessTimeEnum.SPECIFIC && specificTimes.length === 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(ScheduleLocales.timeSlotRequired),
              path: ['specificTimes'],
            })
          } else if (accessTimeType === AccessRuleAccessTimeEnum.TIME_RANGE && (!startTime || !endTime)) {
            if (!startTime) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(ScheduleLocales.startTimeRequired),
                path: ['startTime'],
              })
            }
            if (!endTime) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(ScheduleLocales.endTimeRequired),
                path: ['endTime'],
              })
            }
          }
        }),
    [dateRange, selectedDays, formatMessage]
  )
}

export function getInitialSchedule(arState: {
  accessRule?: AccessRule
  startDate: Date
  startTimeDisplay?: string
  endTimeDisplay?: string
  shiftCategories: ShiftCategory[]
  startOfDayTime: string
}): ScheduleForm {
  const { shiftCategories } = arState.accessRule ?? {}
  const startDate = arState.accessRule?.isOverride ? arState.accessRule?.date : arState.accessRule?.startDate
  const endDate = arState.accessRule?.isOverride ? arState.accessRule?.date : arState.accessRule?.endDate
  const timeSlots = generateTimeSlots(arState.startOfDayTime)
  const [startTime, endTime] = [arState.startTimeDisplay, arState.endTimeDisplay]
    .map(t => timeSlots.find(option => option.label === t))
    .map(t => t?.id)

  const overrideDay = arState.accessRule?.date?.toJsDate().getDay()

  return {
    accessTimeType: arState.accessRule?.accessTimeType ?? 'ALL',
    shiftCategories: shiftCategories ?? arState.shiftCategories,
    restrictToShifts: arState.accessRule?.restrictToShifts ?? false,
    specificTimes: arState.accessRule?.specificTimes ?? [],
    startTime: arState.accessRule?.startTimeString ?? startTime ?? null,
    endTime: arState.accessRule?.endTimeString ?? endTime ?? null,
    dateRange: {
      startDate: startDate?.toJsDate() ?? arState.startDate,
      endDate: endDate?.toJsDate() ?? null,
      isInfinite: !endDate,
    },
    selectedDays:
      arState.accessRule?.isOverride && overrideDay
        ? [(overrideDay - 1 + 7) % 7] // for our days of week array, Monday is 0, but for JS Date, Sunday is 0
        : arState.accessRule?.dayOfWeek
            ?.map((checked, idx) => ({ checked, idx }))
            ?.filter(({ checked }) => checked)
            ?.map(({ idx }) => idx) ?? [...Array(7).keys()],
  }
}
