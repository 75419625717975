import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CheckCircleOutlineRounded from '@mui/icons-material/CheckCircleOutlineRounded'
import PanoramaFishEyeRoundedIcon from '@mui/icons-material/PanoramaFishEyeRounded'
import * as React from 'react'
import type { UseForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { Form, LoaderButton } from '@sevenrooms/core/ui-kit/form'
import { messages } from '@sevenrooms/mgr-marketing-templates-gallery/locales/marketingTemplatesGallery.locales'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Step } from '@sevenrooms/react-components/components/Step'
import { Stepper } from '@sevenrooms/react-components/components/Stepper'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import type { CreateOfferFromTemplateFormData } from '../OffersStepperFlow/CreateOfferFromTemplateForm.zod'
import type { PropsWithChildren, ReactElement } from 'react'

interface StepProps {
  title: string
  component: ReactElement
  isValid: () => void
}

export interface Steps {
  [key: string]: StepProps
}

interface StepperIconProps {
  index: number
  activeStep: number
}
interface StepperFlowProps {
  form: UseForm<z.ZodType<never | CreateOfferFromTemplateFormData>>
  steps: Steps
  onCancel: () => {} | void
  onFlowSubmit: () => {} | void
  isFlowSubmitLoading: boolean
  showFooterMenu?: boolean
  isFlowCompleted?: boolean
  setFlowCompleted?: (attr: boolean) => void
}

export function StepperFlow({
  form,
  steps,
  onCancel,
  onFlowSubmit,
  isFlowSubmitLoading,
  showFooterMenu = true,
  isFlowCompleted = false,
  setFlowCompleted,
}: StepperFlowProps) {
  const { formatMessage } = useLocales()
  const theme = useTheme()

  const flowSteps: string[] = Object.keys(steps)
  const [activeStep, setActiveStep] = React.useState(0)

  const currentStepComponent = (activeStepIndex: number): string | undefined => flowSteps[activeStepIndex]

  const handleNext = async () => {
    const isValid = await steps[currentStepComponent(activeStep) as keyof typeof steps]?.isValid()
    if (isValid) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    if (setFlowCompleted) {
      setFlowCompleted(false)
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: '-webkit-center',
      }}
    >
      <Box sx={{ pb: 4, borderBottom: `1px solid ${theme.colors.borders}` }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {flowSteps.map((step, index) => {
            const stepProps: { completed?: boolean } = {}
            return (
              <Step key={steps[step]?.title || ''} {...stepProps}>
                <Stack direction="column" alignItems="center">
                  <StepperIcon index={index} activeStep={activeStep} />
                  <StepperLabel index={index} activeStep={activeStep}>
                    {steps[step]?.title}
                  </StepperLabel>
                </Stack>
              </Step>
            )
          })}
        </Stepper>
      </Box>
      {activeStep === flowSteps.length ? (
        <Typography sx={{ mt: 2, mb: 1, pl: 8 }}>{formatMessage(messages.finalPageTitle)}</Typography>
      ) : (
        <>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flex: 'auto',
            }}
          >
            <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
              {steps[currentStepComponent(activeStep) as keyof typeof steps]?.component}
            </Form>
          </Box>
          {showFooterMenu ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                pt: 2,
                pb: 2,
                borderTop: `1px solid ${theme.colors.borders}`,
                borderRadius: `${theme.borderRadius.s}`,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', pl: 8 }}>
                <Button data-test="cancel-btn" variant="text" disabled={isFlowSubmitLoading} onClick={onCancel}>
                  {formatMessage(messages.cancelButtonText)}
                </Button>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', pr: 8, justifyContent: 'end' }}>
                <Button
                  data-test="handle-back-btn"
                  onClick={handleBack}
                  disabled={isFlowSubmitLoading}
                  sx={{ mr: 1, display: activeStep === 0 ? 'none' : 'flex' }}
                >
                  {formatMessage(messages.backButtonText)}
                </Button>
                {isFlowCompleted || activeStep === flowSteps.length - 1 ? (
                  <LoaderButton data-test="handle-finish-btn" loading={isFlowSubmitLoading} onClick={onFlowSubmit}>
                    {formatMessage(messages.finishButtonText)}
                  </LoaderButton>
                ) : (
                  <ArrowButton onClick={handleNext}>{formatMessage(messages.nextButtonText)}</ArrowButton>
                )}
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
    </Box>
  )
}

function StepperIcon({ index, activeStep }: StepperIconProps) {
  if (index === activeStep) {
    return <PanoramaFishEyeRoundedIcon color="primary" />
  } else if (index < activeStep) {
    return <CheckCircleOutlineRounded color="primary" />
  }
  return <PanoramaFishEyeRoundedIcon color="disabled" />
}

function StepperLabel({ index, activeStep, children }: PropsWithChildren<StepperIconProps>) {
  const theme = useTheme()
  const textColor = index <= activeStep ? `${theme.colors.primaryFont}` : `${theme.colors.secondaryFont}`
  return <Typography sx={{ pt: 3, pl: 1, fontSize: 14, fontWeight: 400, color: textColor }}>{children}</Typography>
}

function ArrowButton({ onClick, children }: { onClick: () => {}; children: PropsWithChildren<string> }) {
  return (
    <Button data-test="handle-next-btn" variant="contained" onClick={onClick}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        <Typography>{children}</Typography>
        <ArrowForwardIosIcon sx={{ fontSize: '14px', alignSelf: 'center' }} />
      </Box>
    </Button>
  )
}
