import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  offerDetailsStepTitle: {
    id: 'offerTemplates.offerDetailsStepTitle',
    defaultMessage: "Let's get started! First we'll need a few guest-facing details.",
  },
  offerDetailsStepDescription: {
    id: 'offerTemplates.offerDetailsStepDescription',
    defaultMessage: 'Offer details have been prefilled by the template',
  },
  offerTitle: {
    id: 'offerTemplates.offerTitle',
    defaultMessage: 'What do you want to call your Offer?',
  },
  offerTitleRecommendation: {
    id: 'offerTemplates.offerTitleRecommendation',
    defaultMessage: 'Names with common phrases perform best since they are easily recognizable and searchable by guests.',
  },
  offerDescription: {
    id: 'offerTemplates.offerDescription',
    defaultMessage: 'How do you want to describe this Offer to guests?',
  },
  offerPreviewDescription: {
    id: 'offerTemplates.offerPreviewDescription',
    defaultMessage: 'What preview description do you want on your widget? (1-2 words)',
  },
  offerPreviewDescriptionPlaceholder: {
    id: 'offerTemplates.offerPreviewDescriptionPlaceholder',
    defaultMessage: 'Enter a timeslot description',
  },
  offerPreviewDescriptionRecommendation: {
    id: 'offerTemplates.offerPreviewDescriptionRecommendation',
    defaultMessage: 'Brief and informative descriptions will attract more bookings.',
  },
  offerPrice: {
    id: 'offerTemplates.offerPrice',
    defaultMessage: 'What price is your Offer?',
  },
  offerImage: {
    id: 'offerTemplates.offerImage',
    defaultMessage: 'What image would you like to use for this Offer?',
  },
  availabilityStepTitle: {
    id: 'offerTemplates.availabilityStepTitle',
    defaultMessage: "Next let's decide where you want {offerName} to be made available.",
  },
  availabilityStepDescription: {
    id: 'offerTemplates.availabilityStepDescription',
    defaultMessage: 'To make {offerName} available to book on your Reservation or Events widget, it must be linked to an Access Rule.',
  },
  createNewAccessRuleButtonText: {
    id: 'offerTemplates.createNewAccessRuleButtonText',
    defaultMessage: 'Create New Access Rule',
  },
  linkToExistingAccessRuleButtonText: {
    id: 'offerTemplates.linkToExistingAccessRuleButtonText',
    defaultMessage: 'Link an Existing Access Rule',
  },
  linkToExistingAccessRuleStepTitle: {
    id: 'offerTemplates.linkToExistingAccessRuleStepTitle',
    defaultMessage: 'Link {offerName} to existing Access Rule(s)',
  },
  linkToExistingAccessRuleRecommendation: {
    id: 'offerTemplates.linkToExistingAccessRuleRecommendation',
    defaultMessage: 'You can select multiple access rules to link.',
  },
  selectAccessRulesToLinkTheOfferTo: {
    id: 'offerTemplates.selectAccessRulesToLinkTheOfferTo',
    defaultMessage: 'Select Access Rules to link your Offer to',
  },
  finalPageTitle: {
    id: 'offerTemplates.finalPageTitle',
    defaultMessage: 'Congratulations! Your new {offerName} offer is live.',
  },
  finalPageDescription: {
    id: 'offerTemplates.finalPageDescription',
    defaultMessage: 'Use this link to include on your website, send out an email blast or post to social media.',
  },
  viewOfferLandingPageButtonText: {
    id: 'offerTemplates.viewOfferLandingPageButtonText',
    defaultMessage: 'View Offer Landing Page',
  },
  viewOffersButtonText: {
    id: 'offerTemplates.viewOffersButtonText',
    defaultMessage: 'View Offers',
  },
  experienceAPIError: {
    id: 'offerTemplates.experienceAPIError',
    defaultMessage: 'Something went wrong, please try again or contact support team.',
  },
  recommendationTipBestPractices: {
    id: 'offerTemplates.recommendationTipBestPractices',
    defaultMessage: 'Best practices',
  },
  recommendationTipHelpfulTip: {
    id: 'offerTemplates.recommendationTipHelpfulTip',
    defaultMessage: 'Helpful tip',
  },
})
