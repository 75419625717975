import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { type OneTimeEmailCampaignType, useDeleteEmailCampaignByIdMutation, useUpdateEmailCampaignsMutation } from '@sevenrooms/core/api'
import { type GenericTagGroup, OneTimeEmailCampaignStatusEnum } from '@sevenrooms/core/domain'
import { commonMessages, useLocales, Locale } from '@sevenrooms/core/locales'
import { Surface, useDestination, useNavigation } from '@sevenrooms/core/navigation'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { useScreenWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  Box,
  Loader,
  notify,
  SECONDARY_HEADER_ID,
  Spreadsheet,
  BasicCell,
  EmptyState,
  RowDivider,
  Window,
  Tooltip,
  type DataTableColumn,
  type Row,
  type EmptyStateProps,
} from '@sevenrooms/core/ui-kit/layout'
import { Link, StatusLabel, type StatusLabelVariant, Text } from '@sevenrooms/core/ui-kit/typography'
import {
  marketingCommonMessages,
  campaignCenterMessages,
  type OneTimeEmailCampaignWithActivity,
  useEmailCampaignPerformanceContext,
} from '@sevenrooms/marketing'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { routes } from '@sevenrooms/routes'
import mailbox from '../../assets/images/mailbox.svg'
import { oneTimeEmailCenterMessages } from '../../locales'
import { ConfirmArchiveOneTimeEmailModal } from '../ConfirmArchiveOneTimeEmailModal'
import { ConfirmEditOneTimeEmailModal } from '../ConfirmEditOneTimeEmailModal'

export function OneTimeEmailCampaigns() {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const nav = useNavigation()
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const canManageEmailsWithGlobalTags = useAppContext().userDomain?.canManageEmailsWithGlobalTags
  const [updateEmailCampaignsQuery] = useUpdateEmailCampaignsMutation()
  const [deleteEmailCampaignMutation] = useDeleteEmailCampaignByIdMutation()
  const { venueSettings } = useVenueSettingsContext()
  const canAccessDeepDiveView = venueSettings?.is_email_marketing_deep_dive_enabled

  const { breakpoint } = useScreenWidthBreakpoint()
  const isLargeScreen = _.includes(['l', 'xl'], breakpoint)
  const locale = Locale.getLocale(venue?.locale)
  const currencySymbol = venue?.currencySymbol
  const { params } = useDestination(routes.manager2.marketing.emailCenter.emails.archiveEmail)
  const campaignId = params?.campaignId
  const closeArchiveEmailCampaignHref = nav.closeSurfaceHref(routes.manager2.marketing.oneTimeEmailCenter.confirmArchiveModal, {
    params: { venueKey: venue.urlKey, campaignId },
  })

  const { campaigns, filteredCampaigns, campaignStatus, searchValue, isError, isFetching, isPOSActivated, averageSpendPerCover } =
    useEmailCampaignPerformanceContext()

  const statusFilters: SelectOption<OneTimeEmailCampaignStatusEnum>[] = useMemo(
    () => [
      { id: OneTimeEmailCampaignStatusEnum.SENT, label: formatMessage(marketingCommonMessages.sent) },
      { id: OneTimeEmailCampaignStatusEnum.ACTIVE, label: formatMessage(marketingCommonMessages.scheduled) },
      { id: OneTimeEmailCampaignStatusEnum.DRAFT, label: formatMessage(marketingCommonMessages.draft) },
    ],
    [formatMessage]
  )

  const onCampaignEditConfirm = useCallback(
    async (campaignId: string) => {
      try {
        await updateEmailCampaignsQuery({
          emailCampaignIds: [campaignId],
          emailCampaignUpdate: { status: OneTimeEmailCampaignStatusEnum.INACTIVE },
          venueId: venue.id || '',
        })
        nav.push(routes.manager2.marketing.oneTimeEmailCenter.editOneTimeEmail, {
          params: { venueKey: venue.urlKey, campaignId },
          queryMode: 'clear',
        })
      } catch {
        notify({
          content: formatMessage(campaignCenterMessages.campaignSaveError),
          type: 'error',
        })
      }
    },
    [formatMessage, nav, updateEmailCampaignsQuery, venue]
  )

  const onCampaignEditClick = useCallback(
    (campaign: OneTimeEmailCampaignType) => {
      if (campaign.status === OneTimeEmailCampaignStatusEnum.DRAFT) {
        nav.push(routes.manager2.marketing.oneTimeEmailCenter.editOneTimeEmail, {
          params: { venueKey: venue.urlKey, campaignId: campaign.id },
          queryMode: 'clear',
        })
      } else {
        nav.push(routes.manager2.marketing.oneTimeEmailCenter.confirmEditModal, {
          params: { venueKey: venue.urlKey, campaignId: campaign.id },
        })
      }
    },
    [nav, venue]
  )

  const onCampaignArchiveConfirm = useCallback(
    async (campaignId: string) => {
      try {
        await deleteEmailCampaignMutation({ campaignId, venueId: venue.id || '' }).unwrap()

        notify({
          content: formatMessage(oneTimeEmailCenterMessages.campaignSaveSuccess),
          type: 'success',
        })

        nav.closeSurface(closeArchiveEmailCampaignHref)
      } catch {
        notify({
          content: formatMessage(campaignCenterMessages.campaignSaveError),
          type: 'error',
        })
      }
    },
    [formatMessage, nav, deleteEmailCampaignMutation, venue, closeArchiveEmailCampaignHref]
  )

  const onCampaignArchiveClick = useCallback(
    (campaign: OneTimeEmailCampaignType) => {
      nav.push(routes.manager2.marketing.oneTimeEmailCenter.confirmArchiveModal, {
        params: { venueKey: venue.urlKey, campaignId: campaign.id },
      })
    },
    [nav, venue]
  )

  const onCampaignViewClick = useCallback(
    (campaign: OneTimeEmailCampaignType) => {
      if (canAccessDeepDiveView) {
        nav.push(routes.manager2.marketing.oneTimeEmailCenter.viewDeepDive, {
          params: { venueKey: venue.urlKey, campaignId: campaign.id },
        })
      } else {
        nav.push(routes.manager2.marketing.oneTimeEmailCenter.viewOneTimeEmail, {
          params: { venueKey: venue.urlKey, campaignId: campaign.id },
        })
      }
    },
    [nav, venue, canAccessDeepDiveView]
  )

  const onCampaignDuplicateClick = useCallback(
    (campaignId: string) => {
      nav.push(routes.manager2.marketing.oneTimeEmailCenter.createOneTimeEmail, {
        params: { venueKey: venue.urlKey },
        query: { campaignToDuplicateId: campaignId, parentTemplateId: '' },
      })
    },
    [nav, venue]
  )

  const oneTimeEmailStatusLabelConfig: { [status in OneTimeEmailCampaignStatusEnum]?: { text: string; variant: StatusLabelVariant } } =
    useMemo(
      () => ({
        [OneTimeEmailCampaignStatusEnum.DRAFT]: { text: formatMessage(oneTimeEmailCenterMessages.draft), variant: 'inactive' },
        [OneTimeEmailCampaignStatusEnum.ACTIVE]: { text: formatMessage(oneTimeEmailCenterMessages.scheduled), variant: 'status' },
        [OneTimeEmailCampaignStatusEnum.SENDING]: { text: formatMessage(oneTimeEmailCenterMessages.processing), variant: 'success' },
        [OneTimeEmailCampaignStatusEnum.SENT]: { text: formatMessage(oneTimeEmailCenterMessages.sent), variant: 'success' },
      }),
      [formatMessage]
    )

  const getCampaignStatusDate = (campaign: OneTimeEmailCampaignType) => {
    if (campaign.status === OneTimeEmailCampaignStatusEnum.SENT) {
      return campaign.sentDateTimeLocal
    } else if (campaign.status === OneTimeEmailCampaignStatusEnum.ACTIVE) {
      return campaign.oneTimeSendTimeConfig?.scheduledDate
    }

    return campaign.updated
  }

  const sortCampaigns = (campaigns: OneTimeEmailCampaignType[]) => {
    const precendence = [
      OneTimeEmailCampaignStatusEnum.DRAFT,
      OneTimeEmailCampaignStatusEnum.ACTIVE,
      OneTimeEmailCampaignStatusEnum.SENT,
      OneTimeEmailCampaignStatusEnum.SENDING,
    ]

    const campaignGroups = _.groupBy(campaigns, campaign => getCampaignStatusDate(campaign)?.toDateOnly()?.toJsDate()?.setDate(0))

    const campaignGroupsOrderedByDate = Object.keys(campaignGroups)
      .sort()
      .reverse()
      .reduce<typeof campaignGroups>(
        (obj, key) =>
          ({
            ...obj,
            [key]: campaignGroups[key],
          } as typeof campaignGroups),
        {}
      )

    return _.flatten(
      _.values(
        _.mapValues(campaignGroupsOrderedByDate, campaigns =>
          _.orderBy(
            campaigns,
            [
              campaign => _.findIndex(precendence, status => campaign.status === status),
              campaign => getCampaignStatusDate(campaign)?.toJsDate(),
            ],
            ['asc', 'desc']
          )
        )
      )
    )
  }

  const renderPreComponent = ({
    row,
    index,
    rows,
    containerWidth,
  }: {
    row: Row<OneTimeEmailCampaignWithActivity>
    index: number
    rows: Row<OneTimeEmailCampaignWithActivity>[]
    containerWidth: React.CSSProperties['width']
  }) => {
    const previousCampaign = rows[index - 1]?.original
    const previousCampaignDate = previousCampaign && getCampaignStatusDate(previousCampaign)?.toJsDate()
    const currentCampaignDate = getCampaignStatusDate(row?.original)
    const currentCampaignJsDate = currentCampaignDate?.toJsDate()

    if (!previousCampaign || previousCampaignDate?.getMonth() !== currentCampaignJsDate?.getMonth()) {
      const count = _.size(
        rows.filter(value => {
          const campaignDate = getCampaignStatusDate(value?.original)?.toJsDate()

          return (
            campaignDate?.getFullYear() === currentCampaignJsDate?.getFullYear() &&
            campaignDate?.getMonth() === currentCampaignJsDate?.getMonth()
          )
        })
      )

      return (
        <RowDivider width={containerWidth}>
          {currentCampaignDate?.formatFMonthNYear()}&nbsp;({count})
        </RowDivider>
      )
    }

    return null
  }

  const getCampaignStatusDescription = useCallback(
    (campaign: OneTimeEmailCampaignWithActivity) => {
      const date = getCampaignStatusDate(campaign)?.formatNYearFMonthNDaySWeekSTime() || ''
      const guests = campaign?.oneTimeSendTimeConfig?.estimatedNumClients
      const formattedRecipientClientTags = campaign?.formattedRecipientClientTags
      const audience = formattedRecipientClientTags && (
        <>
          <br />
          {formatMessage(oneTimeEmailCenterMessages.campaignNameToDescription)}
          &nbsp;
          {formattedRecipientClientTags.map((formattedRecipientClientTag: string | GenericTagGroup, i: number) => (
            <Text color="primaryFont" overflowWrap="break-word" key={`${campaign.campaignName}_${formattedRecipientClientTag}`}>
              {i !== formattedRecipientClientTags.length - 1 ? `${formattedRecipientClientTag}, ` : formattedRecipientClientTag}
            </Text>
          ))}
          &nbsp;
          {guests != null && (
            <Text color="secondaryFont" whiteSpace="nowrap">
              {formatMessage(campaignCenterMessages.campaignNameGuestsDescription, { guests })}
            </Text>
          )}
        </>
      )

      if (campaign.status === OneTimeEmailCampaignStatusEnum.SENT) {
        return (
          <>
            {formatMessage(campaignCenterMessages.campaignNameSentDescription, {
              date,
            })}
            {audience}
          </>
        )
      } else if (campaign.status === OneTimeEmailCampaignStatusEnum.ACTIVE) {
        return (
          <>
            {formatMessage(campaignCenterMessages.campaignNameScheduledDescription, {
              date,
            })}
            {audience}
          </>
        )
      } else if (campaign.status === OneTimeEmailCampaignStatusEnum.SENDING) {
        return formatMessage(campaignCenterMessages.campaignNameSendingDescription, {
          date,
        })
      }

      return formatMessage(campaignCenterMessages.campaignNameDraftDescription, {
        date,
      })
    },
    [formatMessage]
  )

  const getActionMenu = (value: OneTimeEmailCampaignWithActivity) => (
    <Menu
      scrollLock
      onOpen={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
      disabled={
        _.includes([OneTimeEmailCampaignStatusEnum.SENDING], value.status) || (value.usesGlobalTag && !canManageEmailsWithGlobalTags)
      }
    >
      {_.includes([OneTimeEmailCampaignStatusEnum.DRAFT, OneTimeEmailCampaignStatusEnum.ACTIVE], value.status) && (
        <MenuItem onClick={() => onCampaignEditClick(value)}>
          <Icon name="VMSWeb-edit" />
          {formatMessage(commonMessages.edit)}
        </MenuItem>
      )}

      {_.includes([OneTimeEmailCampaignStatusEnum.SENT, OneTimeEmailCampaignStatusEnum.SENDING], value.status) && (
        <MenuItem onClick={() => onCampaignViewClick(value)}>
          <Icon name="VMSWeb-eye" />
          {formatMessage(commonMessages.view)}
        </MenuItem>
      )}

      {value.usingTopolEditor && (
        <MenuItem data-test="sr-button-duplicate-email-campaign" onClick={() => onCampaignDuplicateClick(value.id)}>
          <Icon name="VMSWeb-copy" />
          {formatMessage(commonMessages.duplicate)}
        </MenuItem>
      )}

      {_.includes([OneTimeEmailCampaignStatusEnum.DRAFT], value.status) && (
        <MenuItem data-test="sr-button-archive-email-campaign" onClick={() => onCampaignArchiveClick(value)}>
          <Icon name="VMSWeb-trash" />
          {formatMessage(commonMessages.archive)}
        </MenuItem>
      )}
    </Menu>
  )

  const columns: DataTableColumn<OneTimeEmailCampaignWithActivity>[] = [
    {
      key: 'campaignName',
      header: formatMessage(oneTimeEmailCenterMessages.campaignName),
      accessor: 'campaignName',
      render: (value, index) => (
        <Box minHeight={36} overflow="hidden" boxSizing="content-box" data-test={`one-time-emails-${index}-campaign-name`}>
          <Box mb="xs">
            <Text textOverflow="twoLinesEllipsis">{value.campaignName}</Text>
          </Box>

          {value?.emailSubject && (
            <Text color="secondaryFont" textOverflow="twoLinesEllipsis">
              {value.emailSubject}
            </Text>
          )}
        </Box>
      ),
      attach: 'left',
      minWidth: 270,
      maxWidth: isLargeScreen ? undefined : 270,
      width: isLargeScreen ? undefined : 270,
      alignItems: 'start',
    },
    {
      key: 'status',
      header: formatMessage(campaignCenterMessages.status),
      accessor: 'status',
      render: (value, index) => (
        <Box overflow="hidden" boxSizing="content-box" data-test={`one-time-emails-${index}-status`}>
          {value?.status && (
            <Box mb="xs">
              <StatusLabel variant={oneTimeEmailStatusLabelConfig[value.status]?.variant || 'inactive'}>
                {oneTimeEmailStatusLabelConfig[value.status]?.text}
              </StatusLabel>
            </Box>
          )}
          <Text color="secondaryFont">{getCampaignStatusDescription(value)}</Text>
        </Box>
      ),
      minWidth: 350,
      maxWidth: isLargeScreen ? undefined : 350,
      width: isLargeScreen ? undefined : 350,
      alignItems: 'start',
    },
    {
      key: 'recipients',
      header: formatMessage(campaignCenterMessages.recipients),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeEmailCampaignWithActivity) => value?.campaignActivity?.recipients,
      render: (value, index) =>
        value.status === OneTimeEmailCampaignStatusEnum.SENT && value.campaignActivity ? (
          <BasicCell primary={value.campaignActivity.recipients.toLocaleString(locale)} data-test={`one-time-emails-${index}-recipients`} />
        ) : (
          '-'
        ),
      minWidth: 108,
      alignItems: 'start',
    },
    {
      key: 'opens',
      header: formatMessage(campaignCenterMessages.opens),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeEmailCampaignWithActivity) => value?.campaignActivity?.opens,
      render: (value, index) =>
        value.status === OneTimeEmailCampaignStatusEnum.SENT && value.campaignActivity ? (
          <BasicCell
            primary={value.campaignActivity.opens.toLocaleString(locale)}
            secondary={`${Math.ceil((value.campaignActivity.opens / value.campaignActivity.recipients) * 100) || 0}%`}
            data-test={`one-time-emails-${index}-opens`}
          />
        ) : (
          '-'
        ),
      minWidth: 90,
      alignItems: 'start',
    },
    {
      key: 'clicks',
      header: formatMessage(campaignCenterMessages.clicks),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeEmailCampaignWithActivity) => value?.campaignActivity?.clicks,
      render: (value, index) =>
        value.status === OneTimeEmailCampaignStatusEnum.SENT && value.campaignActivity ? (
          <BasicCell
            primary={value.campaignActivity.clicks.toLocaleString(locale)}
            secondary={`${Math.ceil((value.campaignActivity.clicks / value.campaignActivity.recipients) * 100) || 0}%`}
            data-test={`one-time-emails-${index}-clicks`}
          />
        ) : (
          '-'
        ),
      minWidth: 90,
      alignItems: 'start',
    },
    {
      key: 'totalRevenue',
      header: formatMessage(campaignCenterMessages.revenue),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeEmailCampaignWithActivity) => value?.campaignActivity?.totalRevenue,
      render: (value, index) =>
        value.status === OneTimeEmailCampaignStatusEnum.SENT && value.campaignActivity ? (
          <BasicCell
            primary={`${currencySymbol ?? ''}${Math.round(
              parseFloat(
                `${
                  !isPOSActivated && averageSpendPerCover
                    ? value.campaignActivity.covers * averageSpendPerCover
                    : value.campaignActivity.totalRevenue
                }`
              )
            ).toLocaleString(locale)}`}
            data-test={`one-time-emails-${index}-revenue`}
          />
        ) : (
          '-'
        ),
      minWidth: 87,
      alignItems: 'start',
    },
    {
      key: 'reservations',
      header: formatMessage(campaignCenterMessages.reservations),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeEmailCampaignWithActivity) => value?.campaignActivity?.reservations,
      render: (value, index) =>
        value.status === OneTimeEmailCampaignStatusEnum.SENT && value.campaignActivity ? (
          <BasicCell
            primary={value.campaignActivity.reservations.toLocaleString(locale)}
            data-test={`one-time-emails-${index}-reservations`}
          />
        ) : (
          '-'
        ),
      minWidth: 108,
      alignItems: 'start',
    },
    {
      key: 'crossSelling',
      header: formatMessage(campaignCenterMessages.crossPromos),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeEmailCampaignWithActivity) => value?.campaignActivity?.crossSelling,
      render: (value, index) =>
        value.status === OneTimeEmailCampaignStatusEnum.SENT && value.campaignActivity ? (
          <BasicCell
            primary={value.campaignActivity.crossSelling.toLocaleString(locale)}
            data-test={`one-time-emails-${index}-cross-selling`}
          />
        ) : (
          '-'
        ),
      minWidth: 108,
      alignItems: 'start',
    },
    {
      key: 'covers',
      header: formatMessage(campaignCenterMessages.covers),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeEmailCampaignWithActivity) => value?.campaignActivity?.covers,
      render: (value, index) =>
        value.status === OneTimeEmailCampaignStatusEnum.SENT && value.campaignActivity ? (
          <BasicCell primary={value.campaignActivity.covers.toLocaleString(locale)} data-test={`one-time-emails-${index}-covers`} />
        ) : (
          '-'
        ),
      minWidth: 76,
      alignItems: 'start',
    },
    {
      key: 'orders',
      header: formatMessage(campaignCenterMessages.orders),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeEmailCampaignWithActivity) => value?.campaignActivity?.orders,
      render: (value, index) =>
        value.status === OneTimeEmailCampaignStatusEnum.SENT && value.campaignActivity ? (
          <BasicCell primary={value.campaignActivity.orders.toLocaleString(locale)} data-test={`one-time-emails-${index}-orders`} />
        ) : (
          '-'
        ),
      minWidth: 76,
      alignItems: 'start',
    },
    {
      key: 'unsubscribed',
      header: formatMessage(campaignCenterMessages.unsubscribed),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeEmailCampaignWithActivity) => value?.campaignActivity?.unsubscribed,
      render: (value, index) =>
        value.status === OneTimeEmailCampaignStatusEnum.SENT && value.campaignActivity ? (
          <BasicCell
            primary={value.campaignActivity.unsubscribed.toLocaleString(locale)}
            secondary={`${Math.ceil((value.campaignActivity.unsubscribed / value.campaignActivity.recipients) * 100) || 0}%`}
            data-test={`one-time-emails-${index}-unsubscribed`}
          />
        ) : (
          '-'
        ),
      minWidth: 119,
      alignItems: 'start',
    },
    {
      key: 'bounces',
      header: formatMessage(campaignCenterMessages.bounce),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeEmailCampaignWithActivity) => value?.campaignActivity?.bounces,
      render: value =>
        value.status === OneTimeEmailCampaignStatusEnum.SENT && value.campaignActivity ? (
          <BasicCell
            primary={value.campaignActivity.bounces.toLocaleString(locale)}
            secondary={`${Math.ceil((value.campaignActivity.bounces / value.campaignActivity.recipients) * 100) || 0}%`}
          />
        ) : (
          '-'
        ),
      minWidth: 90,
      alignItems: 'start',
    },
    {
      key: 'complaints',
      header: formatMessage(campaignCenterMessages.complaints),
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      accessor: (value: OneTimeEmailCampaignWithActivity) => value?.campaignActivity?.complaints,
      render: value =>
        value.status === OneTimeEmailCampaignStatusEnum.SENT && value.campaignActivity ? (
          <BasicCell
            primary={value.campaignActivity.complaints.toLocaleString(locale)}
            secondary={`${Math.ceil((value.campaignActivity.complaints / value.campaignActivity.recipients) * 100) || 0}%`}
          />
        ) : (
          '-'
        ),
      minWidth: 90,
      alignItems: 'start',
    },
    {
      key: 'action',
      header: '',
      textDisplay: 'flex',
      headerAlign: 'end',
      cellAlign: 'end',
      render: value => {
        if (value.usesGlobalTag && !canManageEmailsWithGlobalTags) {
          return (
            <Tooltip
              content={<span>{formatMessage(campaignCenterMessages.campaignTableEditPermissionTooltip)}</span>}
              displayAction="hover"
              alignment="top"
            >
              <Box textAlign="right">{getActionMenu(value)}</Box>
            </Tooltip>
          )
        }
        return getActionMenu(value)
      },
      attach: 'right',
      expandable: true,
      minWidth: 64,
      maxWidth: 64,
      alignItems: 'start',
    },
  ]

  const getEmptyState = (): React.ReactNode | undefined => {
    const emptyStateProps: EmptyStateProps = {}
    if (_.isEmpty(campaigns)) {
      emptyStateProps.image = mailbox
      emptyStateProps.title = formatMessage(oneTimeEmailCenterMessages.emptyTableTitleNoData)
      emptyStateProps.description = (
        <>
          {formatMessage(oneTimeEmailCenterMessages.emptyTableDescriptionNoData)}&nbsp;
          <Link
            to={nav.href(routes.manager2.marketing.oneTimeEmailCenter.createOneTimeEmail, {
              params: { venueKey: venue.urlKey },
            })}
          >
            {formatMessage(oneTimeEmailCenterMessages.emptyTableDescriptionLinkNoData)}
          </Link>
        </>
      )
      emptyStateProps.alt = formatMessage(oneTimeEmailCenterMessages.emptyTableTitleNoData)
    } else if (_.isEmpty(filteredCampaigns)) {
      const descriptionText = formatMessage(
        [0, statusFilters.length].includes(campaignStatus.length)
          ? oneTimeEmailCenterMessages.emptyTableDescriptionSearch
          : oneTimeEmailCenterMessages.emptyTableDescriptionFilters,
        { searchValue }
      )
      emptyStateProps.description = <Text color="secondaryFont">{descriptionText}</Text>
      emptyStateProps.alt = descriptionText
    }
    return emptyStateProps.description ? (
      <EmptyState
        title={emptyStateProps.title}
        image={emptyStateProps.image}
        description={emptyStateProps.description}
        alt={emptyStateProps.alt}
      />
    ) : undefined
  }

  if (!venue || isError) {
    notify({ content: formatMessage(campaignCenterMessages.fetchErrorMessage), type: 'error' })
    return null
  }

  if (isFetching) {
    return (
      <Box pb="m">
        <Loader />
      </Box>
    )
  }

  const data = sortCampaigns((filteredCampaigns as OneTimeEmailCampaignWithActivity[]) || [])

  return (
    <>
      <Spreadsheet
        data={data}
        columns={columns}
        autoResetExpanded={false}
        isVerticalScrollBlocked={isMenuOpen}
        noPagination
        renderPreComponent={renderPreComponent}
        placeholder={getEmptyState()}
        noBorder
        pin={document.getElementById(SECONDARY_HEADER_ID)}
      />

      <Surface destination={routes.manager2.marketing.oneTimeEmailCenter.confirmEditModal}>
        <Window>
          <ConfirmEditOneTimeEmailModal onConfirm={onCampaignEditConfirm} />
        </Window>
      </Surface>

      <Surface destination={routes.manager2.marketing.oneTimeEmailCenter.confirmArchiveModal}>
        <Window>
          <ConfirmArchiveOneTimeEmailModal onConfirm={onCampaignArchiveConfirm} closeHref={closeArchiveEmailCampaignHref} />
        </Window>
      </Surface>
    </>
  )
}
