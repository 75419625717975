import { type PropsWithChildren, type ReactElement, useCallback, useState } from 'react'
import { useCreateExperienceMutation } from '@sevenrooms/core/api'
import { useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Box, notify } from '@sevenrooms/core/ui-kit/layout'
import { messages } from '@sevenrooms/mgr-marketing-templates-gallery/locales/marketingTemplatesGallery.locales'
import { Modal } from '@sevenrooms/react-components/components/Modal'
import { routes } from '@sevenrooms/routes'
import { StepperFlow, type Steps } from '../StepperFlow'
import { Availability } from './Availability'
import { formToExperience, useCreateOfferFromTemplateForm, useDefaultValues } from './CreateOfferFromTemplateForm.zod'
import { FinalPage } from './FinalPage'
import { OfferDetails } from './OfferDetails'
import type { TemplateProps } from '../MarketingTemplatesGallery'

export function OffersStepperFlow({
  templateData,
  venueKey,
  venueName,
  venueId,
}: {
  templateData: TemplateProps
  venueKey: string
  venueName: string
  venueId: string
}) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  const createOfferFromTemplateSchema = useCreateOfferFromTemplateForm()
  const defaultValues = useDefaultValues(templateData, venueName)

  const [showFooterMenu, setShowFooterMenu] = useState(true)
  const [experienceFlowCompleted, setExperienceFlowCompleted] = useState(false)
  const [createdExperience, setCreatedExperience] = useState(undefined)
  const [isCreateExperienceLoading, setIsCreateExperienceLoading] = useState(false)
  const [createExperience] = useCreateExperienceMutation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const form = useForm(createOfferFromTemplateSchema, { defaultValues, mode: 'onChange' })

  const handleOnSubmit = useCallback(async () => {
    const { getValues } = form
    const values = getValues()
    const createExperienceData = await formToExperience({
      formData: values,
      onError: () => {
        setIsCreateExperienceLoading(false)
        notify({
          content: formatMessage(messages.experienceAPIError),
          type: 'error',
        })
      },
    })

    return createExperience({
      experience: createExperienceData,
      venueId,
    })
  }, [form, createExperience, venueId, formatMessage])

  const onCancel = () => {
    nav.push(routes.manager2.marketing.offers, { params: { venueKey } })
  }

  const onFlowSubmit = () => {
    setIsCreateExperienceLoading(true)
    handleOnSubmit().then(result => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setCreatedExperience(result?.data || undefined)
    })
  }

  const steps: Steps = {
    offerDetails: {
      title: formatMessage(messages.stepOfferDetails),
      component: <OfferDetails form={form} />,
      isValid: () => form.trigger(['name', 'description', 'price']),
    },
    availability: {
      title: formatMessage(messages.stepAvailability),
      component: (
        <Availability form={form} venueId={venueId} setShowFooterMenu={setShowFooterMenu} setFlowCompleted={setExperienceFlowCompleted} />
      ),
      isValid: () => true,
    },
    scheduling: {
      title: formatMessage(messages.stepScheduling),
      component: <></>,
      isValid: () => true,
    },
    payment: {
      title: formatMessage(messages.stepPayment),
      component: <></>,
      isValid: () => true,
    },
    customization: {
      title: formatMessage(messages.stepCustomization),
      component: <></>,
      isValid: () => true,
    },
  }

  return (
    <OffersStepperFlowWrapper key="offers-stepper-flow-window">
      {experienceFlowCompleted && createdExperience ? (
        <FinalPage venueUrlKey={venueKey} experience={createdExperience} form={form} />
      ) : (
        <StepperFlow
          form={form}
          steps={steps}
          onCancel={onCancel}
          onFlowSubmit={onFlowSubmit}
          isFlowSubmitLoading={isCreateExperienceLoading}
          isFlowCompleted={experienceFlowCompleted}
          setFlowCompleted={setExperienceFlowCompleted}
          showFooterMenu={showFooterMenu}
        />
      )}
    </OffersStepperFlowWrapper>
  )
}

function OffersStepperFlowWrapper({ children }: { children: PropsWithChildren<ReactElement> }) {
  return (
    <Modal open sx={{ overflow: 'auto', height: '100%', width: '100%' }} data-test="offers-stepper-flow-window">
      <Box width="100vw" height="100vh" pt="m" backgroundColor="inputBackground" overflow="auto" data-test="offers-stepper-flow-window">
        {children}
      </Box>
    </Modal>
  )
}
