import { useMemo } from 'react'
import { isShiftCategory, type ShiftCategory, ShiftCategoryEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormMultiSelect } from '@sevenrooms/core/ui-kit/form'
import { useAccessRuleContext } from '../../shared'
import { ScheduleLocales } from '../Schedule.locales'
import { ScheduleTestId } from '../Schedule.testIds'
import { getShiftCategoryName } from '../utils'
import type { ScheduleProps } from '../ScheduleProps'

export function ShiftSelector({ field }: ScheduleProps) {
  const { allShifts: shifts } = useAccessRuleContext()
  const { formatMessage } = useLocales()

  const options: SelectOption<ShiftCategory>[] = useMemo(() => {
    const allShifts = new Map<ShiftCategory, number>()
    for (const category in ShiftCategoryEnum) {
      if (isShiftCategory(category)) {
        allShifts.set(category, 0)
      }
    }

    const categoryCounts = shifts
      .filter(({ shiftCategory }) => shiftCategory)
      .reduce((acc, { shiftCategory }) => {
        if (shiftCategory) {
          acc.set(shiftCategory, (acc.get(shiftCategory) ?? 0) + 1)
        }
        return acc
      }, allShifts)

    return [...categoryCounts.entries()]
      .map(([category, count]) => ({ category, count, name: getShiftCategoryName(category) }))
      .sort((lhs, rhs) => {
        if (lhs.count === 0 && rhs.count === 0) {
          return 0
        } else if (rhs.count === 0) {
          return -1
        } else if (lhs.count === 0) {
          return 1
        }
        return 0
      })
      .map(({ category, name, count }) => ({ id: category, label: formatMessage(ScheduleLocales.shiftCategoryLabel, { name, count }) }))
  }, [shifts, formatMessage])

  return (
    <FormMultiSelect
      data-test={ScheduleTestId.shiftCategoryMultiSelect}
      field={field.prop('shiftCategories')}
      options={options}
      useCheckboxItems
      searchable
      placeholder={formatMessage(ScheduleLocales.searchShifts)}
    />
  )
}
