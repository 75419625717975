import { FeeTypeEnum } from '@sevenrooms/core/domain'
import { z } from '@sevenrooms/core/form'

export const feeDefaultValues = {
  fees: [],
}

export const TaxSchema = z.object({
  id: z.string(),
  name: z.string(),
  value: z.number(),
})
export const FeeSchema = z.object({
  id: z.string().nullable().default(null),
  name: z.string().nullable().default(null),
  taxRateId: z.string().nullable().default(null),
  feeAmount: z.number().nullable().default(null),
  feeType: z.enum([FeeTypeEnum.FIXED_AMOUNT, FeeTypeEnum.PERCENTAGE]),
  taxInfo: TaxSchema.nullable().default(null),
  venueId: z.string(),
})

export const FeeAPISchema = z
  .object({
    fees: z.array(FeeSchema).default([]),
  })
  .superRefine(() => {})

export type FeeType = z.infer<typeof FeeSchema>
export type FeeAPIForm = z.infer<typeof FeeAPISchema>
