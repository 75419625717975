import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  useTemplateButtonText: {
    id: 'marketingTemplatesLocales.useTemplateButtonText',
    defaultMessage: 'Use Template',
  },
  createExperienceTitle: {
    id: 'marketingTemplatesLocales.createExperienceTitle',
    defaultMessage: 'Create an Experience from a Template',
  },
  createExperienceDescription: {
    id: 'marketingTemplatesLocales.createExperienceDescription',
    defaultMessage: 'Use the new wizard to quickly create a new experience designed to drive demand',
  },
  statusLabelText: {
    id: 'marketingTemplatesLocales.statusLabelText',
    defaultMessage: 'New',
  },
  stepOfferDetails: {
    id: 'marketingTemplatesLocales.stepOfferDetails',
    defaultMessage: 'Offer Details',
  },
  stepAvailability: {
    id: 'marketingTemplatesLocales.stepAvailability',
    defaultMessage: 'Availability',
  },
  stepScheduling: {
    id: 'marketingTemplatesLocales.stepScheduling',
    defaultMessage: 'Scheduling',
  },
  stepPayment: {
    id: 'marketingTemplatesLocales.stepPayment',
    defaultMessage: 'Payment',
  },
  stepCustomization: {
    id: 'marketingTemplatesLocales.stepCustomization',
    defaultMessage: 'Customization',
  },
  nextButtonText: {
    id: 'marketingTemplatesLocales.nextButtonText',
    defaultMessage: 'Next',
  },
  backButtonText: {
    id: 'marketingTemplatesLocales.backButtonText',
    defaultMessage: 'Back',
  },
  finishButtonText: {
    id: 'marketingTemplatesLocales.finishButtonText',
    defaultMessage: 'Finish',
  },
  cancelButtonText: {
    id: 'marketingTemplatesLocales.cancelButtonText',
    defaultMessage: 'Cancel',
  },
  recommendationTipBestPractices: {
    id: 'marketingTemplatesLocales.recommendationTipBestPractices',
    defaultMessage: 'Best practices',
  },
  finalPageTitle: {
    id: 'marketingTemplatesLocales.finalPageTitle',
    defaultMessage: 'Congratulations! Your new offer is live.',
  },
  fieldLengthError: {
    id: 'marketingTemplatesLocales.fieldLengthError',
    defaultMessage: 'Should be at least {minSize} character',
  },
  fieldValueMinError: {
    id: 'marketingTemplatesLocales.fieldValueMinError',
    defaultMessage: 'Should be at least {min}',
  },
  experienceAPIError: {
    id: 'offerTemplates.experienceAPIError',
    defaultMessage: 'Something went wrong, please try again or contact support team.',
  },
})
