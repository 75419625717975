import React, { useMemo } from 'react'
import { type POSILandingData, POSITypeEnum, ProductProvisionPackageTypeEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Banner, Box, Grid, notify, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPage, SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { posiDocumentationLink, posiMetaDataMap } from '../../ReferenceData'
import { POSICard } from '../POSICard'
import { messages } from './SelectPage.locales'

export interface SelectPageProps {
  landingData: POSILandingData[]
}

export function SelectPage({ landingData }: SelectPageProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const nav = useNavigation()
  const venueKey = venue.urlKey

  // Show a notification if the url params indicate an authentication failure
  useMemo(() => {
    const params = new URLSearchParams(window.location.search)
    const authenticationError = params.get('error')
    if (authenticationError) {
      const posName = params.get('pos_name') ?? 'POS'
      const authenticationErrorDescription = params.get('error_description')
      let errorMessage
      switch (authenticationErrorDescription) {
        case 'user_denied':
          errorMessage = messages.userDeniedAuthenticationError
          break
        default:
          errorMessage = messages.defaultAuthenticationError
          break
      }
      notify({ content: formatMessage(errorMessage, { posName }), type: 'error' })
    }
  }, [formatMessage])

  const posiCards = useMemo(
    () =>
      // For POSIs lacking self-service, we show placeholder cards. These placeholders are not always 1:1, so
      // as a workaround we're including placeholder details in the metadata and filtering out redundant integrations here.
      landingData
        .filter(landingData => {
          if (
            [POSITypeEnum.SQUARE, POSITypeEnum.LIGHTSPEED_K, POSITypeEnum.SIMPHONY].includes(landingData.posName as POSITypeEnum) &&
            venue.productProvisionPackage === ProductProvisionPackageTypeEnum.LEGACY
          ) {
            return false
          }
          if (
            !(venue.posIntegrationType === POSITypeEnum.LIGHTSPEED_K && venue.posActivated) &&
            landingData.posName === POSITypeEnum.LIGHTSPEED_K
          ) {
            return false
          }
          return posiMetaDataMap[landingData.posName].selectPageSortOrder >= 0
        })
        .sort((a, b) => (posiMetaDataMap[a.posName].selectPageSortOrder < posiMetaDataMap[b.posName].selectPageSortOrder ? -1 : 1))
        .map(landingData => {
          const { docLink, logo, title, cardSummary, internalSetupLink } = posiMetaDataMap[landingData.posName]
          return (
            <POSICard
              key={landingData.posName}
              logo={logo}
              cardSummary={cardSummary}
              title={title}
              docLink={docLink}
              setupLink={internalSetupLink ? nav.href(internalSetupLink, { params: { venueKey } }) : landingData.externalSetupLink}
            />
          )
        }),
    [nav, landingData, venue.posActivated, venue.posIntegrationType, venue.productProvisionPackage, venueKey]
  )

  return (
    <SettingsPage>
      <SettingsPageMeta title={formatMessage(messages.tabTitle)} />
      <SettingsPageContent
        title={formatMessage(messages.title)}
        description={formatMessage(messages.description, {
          a: (chunks: string[]) => <Anchor href={posiDocumentationLink}>{chunks}</Anchor>,
        })}
        secondHeaderTextMaxWidth="1008px"
      >
        <VStack spacing="l" flexWrap="wrap" p="m" width="1040px">
          <Box minWidth="-webkit-fill-available" width="100%">
            {posiCards ? (
              <Grid columnGap="m" gridTemplateColumns="repeat(auto-fill, minmax(310px, 1fr))">
                {posiCards}
              </Grid>
            ) : (
              <></>
            )}
          </Box>
          <Banner
            title={formatMessage(messages.infoBannerTitle)}
            description={formatMessage(messages.infoBannerDescription)}
            type="info"
            action={formatMessage(messages.infoBannerButton)}
            onAction={() => window.open(posiDocumentationLink)}
          />
        </VStack>
      </SettingsPageContent>
    </SettingsPage>
  )
}
