// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormInput, Label, FormNumberInput } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { MinimumSpendMatrixComponent } from '@sevenrooms/mgr-offers/experiences/components/PDR/MinSpendMatrix'
import type { MinimumSpendMatrixFormData } from '@sevenrooms/mgr-offers/experiences/components/PDR/PDRDetailsForm.zod'
import { spacesMessages } from '../../spaces.locales'
import type { SpaceFormType } from './Space.zod'

interface DinewiseSettingsProps {
  isSubmitting: boolean
  field: Field<SpaceFormType>
  tabSelected: number
  watch: (name?: string | string[]) => void
}

function DinewiseSettings({ isSubmitting, field, tabSelected, watch }: DinewiseSettingsProps) {
  const { formatMessage } = useLocales()
  const { venueSettings } = useVenueSettingsContext()

  return (
    <Block
      pl="lm"
      spacing="lm"
      pb="lm"
      isVisible={!(isSubmitting || tabSelected === 0)}
      maxHeight={!(isSubmitting || tabSelected === 0) ? 'unset' : 0}
    >
      <BaseSection title={formatMessage(spacesMessages.dineWiseOnlyTabWarning)}>
        <Box p="lm">
          <Box backgroundColor="warningBackground" borderRadius="s" pt="s" pb="s" pl="m">
            <Text>{formatMessage(spacesMessages.dineWiseOnlyTabWarningDescription)}</Text>
          </Box>
        </Box>
      </BaseSection>
      <BaseSection>
        <VStack spacing="lm" p="lm">
          <VStack spacing="m">
            <Text fontWeight="bold">{formatMessage(spacesMessages.dwMinSpendTable)}</Text>
            <Label
              primary={formatMessage(spacesMessages.pdrDefaultMinSpend)}
              secondary={formatMessage(spacesMessages.pdrDefaultMinSpendHelper)}
              width="50%"
            >
              <FormNumberInput field={field.prop('pdrMinSpend')} greaterThanZero />
            </Label>
            <MinimumSpendMatrixComponent field={field.prop('pdrMinimumSpendMatrix') as Field<MinimumSpendMatrixFormData[]>} watch={watch} />
          </VStack>
        </VStack>
      </BaseSection>
      {!!venueSettings?.is_triple_seat_dinewise_enabled && (
        <BaseSection title={formatMessage(spacesMessages.pdrTripleSeatIntegrationBoxTitle)}>
          <VStack spacing="lm" p="lm">
            <Label primary={formatMessage(spacesMessages.pdrTripleSeatRoomId)} />
            <FormInput field={field.prop('pdrTripleSeatRoomId')} />
          </VStack>
        </BaseSection>
      )}
      {!!venueSettings?.is_pdr_deposit_fee_enabled && (
        <BaseSection title={formatMessage(spacesMessages.pdrDepositFeeBoxTitle)}>
          <VStack spacing="lm" p="lm">
            <Label primary={formatMessage(spacesMessages.pdrDepositFee)} />
            <FormNumberInput field={field.prop('pdrDepositFee')} greaterThanZero max={100} />
          </VStack>
        </BaseSection>
      )}
    </Block>
  )
}

const Block = styled(VStack)<{ isVisible: boolean }>`
  opacity: ${props => (props.isVisible ? 1 : 0)};
`
export { DinewiseSettings }
