import { defineMessages } from '@sevenrooms/core/locales'

export const spacesMessages = defineMessages({
  pageTitle: {
    id: 'groupBookings.pageTitle',
    defaultMessage: 'Group Bookings',
  },
  createNewSpace: {
    id: 'groupBookings.createNewSpace',
    defaultMessage: 'Create New Space',
  },
  loadingSpaces: {
    id: 'groupBookings.loadingSpaces',
    defaultMessage: 'Loading Spaces',
  },
  noSpacesTitle: {
    id: 'groupBookings.noSpacesTitle',
    defaultMessage: 'No Spaces have been created yet',
  },
  emptySpacesNoData: {
    id: 'groupBookings.emptySpacesNoData',
    defaultMessage: 'Get started by <a>creating new Spaces</a>',
  },
  draftsTitle: {
    id: 'groupBookings.draftsTitle',
    defaultMessage: 'Drafts',
  },
  inactiveTitle: {
    id: 'groupBookings.inactiveTitle',
    defaultMessage: 'Inactive',
  },
  activeTitle: {
    id: 'groupBookings.activeTitle',
    defaultMessage: 'Active',
  },
  activeDescription: {
    id: 'groupBookings.activeDescription',
    defaultMessage: 'Group Booking Spaces will be displayed in the Reservation Widget only.',
  },
  createdBy: {
    id: 'groupBookings.createdBy',
    defaultMessage: 'Created by: {userName} on {createdOn}',
  },
  attachedAccessRules: {
    id: 'groupBookings.attachedAccessRules',
    defaultMessage: `{count, plural,
      =0 {No Access Rules attached}
      one {1 Access Rule attached}
      other {{count} Access Rules attached}
    }`,
  },
  createSpace: {
    id: 'groupBookings.createSpace',
    defaultMessage: 'Create Space',
  },
  draft: {
    id: 'groupBookings.draft',
    defaultMessage: 'Draft',
  },
  spacesTitle: {
    id: 'groupBookings.spacesTitle',
    defaultMessage: 'Spaces',
  },
  spaceDetails: {
    id: 'groupBookings.spaceDetails',
    defaultMessage: 'Space Details',
  },
  spaceDetailsDescription: {
    id: 'groupBookings.spaceDetailsDescription',
    defaultMessage:
      'Spaces are searchable and bookable via the Group Bookings tab of your Reservation Widget. Create a Space for each area of your venue where you accept Group Bookings, including your main dining room. Provide guests with key information, making it easier for them to book directly.',
  },
  spaceName: {
    id: 'groupBookings.spaceName',
    defaultMessage: 'Space Name',
  },
  spaceNameDescription: {
    id: 'groupBookings.spaceNameDescription',
    defaultMessage: 'Guests see your Space Name when they search for Group Bookings on the Reservation Widget.',
  },
  spaceNamePlaceholder: {
    id: 'groupBookings.spaceNamePlaceholder',
    defaultMessage: 'Enter Name',
  },
  descriptionBody: {
    id: 'groupBookings.descriptionBody',
    defaultMessage: 'Description Body',
  },
  descriptionBodyDescription: {
    id: 'groupBookings.descriptionBodyDescription',
    defaultMessage: 'Describe the Space and the experience guests will have. This appears on Reservation Widget.',
  },
  mainImage: {
    id: 'groupBookings.mainImage',
    defaultMessage: 'Main Image',
  },
  mainImageDescription: {
    id: 'groupBookings.mainImageDescription',
    defaultMessage: 'The size of your main Reservation Widget image should be 1500px x 750px and under 2MB.',
  },
  additionalImageLabel: {
    id: 'groupBookings.additionalImageLabel',
    defaultMessage: 'Add up to eight additional images.',
  },
  additionalImageAddButton: {
    id: 'groupBookings.additionalImageAddButton',
    defaultMessage: 'Add Image',
  },
  fees: {
    id: 'groupBookings.fees',
    defaultMessage: 'Display Fees',
  },
  feesDescription: {
    id: 'groupBookings.feesDescription',
    defaultMessage:
      'Display Fees appear on the Reservation Widget, but do not charge payment. Use Access Rule Payment settings to manage payment requirements.',
  },
  allOtherFees: {
    id: 'groupBookings.allOtherFees',
    defaultMessage: 'Additional Fees',
  },
  allOtherFeesDescription: {
    id: 'groupBookings.allOtherFeesDescription',
    defaultMessage:
      'Select Additional Fees to display on the Reservation Widget. Remember to configure any payments you wish to collect under Access Rules.',
  },
  minimumSpendRangeTitle: {
    id: 'groupBookings.minimumSpendRangeTitle',
    defaultMessage: 'Minimum Spend Range',
  },
  minimumSpendRangeDescription: {
    id: 'groupBookings.minimumSpendRangeDescription',
    defaultMessage: 'Provide a Minimum Spend Range for the Space.',
  },
  minimumSpendRangeMinimum: {
    id: 'groupBookings.minimumSpendRangeMinimum',
    defaultMessage: 'Minimum',
  },
  minimumSpendRangeMaximum: {
    id: 'groupBookings.minimumSpendRangeMaximum',
    defaultMessage: 'Maximum',
  },
  pdrAdminFee: {
    id: 'groupBookings.pdrAdminFee',
    defaultMessage: 'Admin fee',
  },
  pdrTaxRateId: {
    id: 'groupBookings.pdrTaxRateId',
    defaultMessage: 'Apply Tax',
  },
  pdrTripleSeatRoomId: {
    id: 'groupBookings.pdrTripleSeatRoomId',
    defaultMessage: 'Triple Seat Room Id',
  },
  dwMinSpendTable: {
    id: 'groupBookings.dwMinSpendTable',
    defaultMessage: 'DineWise Minimum Spend Table',
  },
  pdrDefaultMinSpend: {
    id: 'groupBookings.pdrDefaultMinSpend',
    defaultMessage: 'Default Minimum Spend',
  },
  pdrDefaultMinSpendHelper: {
    id: 'groupBookings.pdrDefaultMinSpendHelper',
    defaultMessage: 'This value will be used if there is no time slot match.',
  },
  errorAPI: {
    id: 'groupBookings.errorAPI',
    defaultMessage: 'Something went wrong, please try again or contact the support',
  },
  spaceUpdated: {
    id: 'groupBookings.spaceUpdated',
    defaultMessage: '{name} is successfully saved.',
  },
  spaceCreated: {
    id: 'groupBookings.spaceCreated',
    defaultMessage: '{name} has been successfully created.',
  },
  pdrTripleSeatIntegrationBoxTitle: {
    id: 'groupBookings.pdrTripleSeatIntegrationBoxTitle',
    defaultMessage: 'TripleSeat Integration',
  },
  pdrDepositFee: {
    id: 'groupBookings.pdrDepositFee',
    defaultMessage: 'Deposit Fee (%)',
  },
  pdrDepositFeeBoxTitle: {
    id: 'groupBookings.pdrDepositFeeBoxTitle',
    defaultMessage: 'Deposits',
  },
  publishSpace: {
    id: 'groupBookings.publishOffer',
    defaultMessage: 'Publish Space',
  },
  pdrUnitOfMeasurement: {
    id: 'groupBookings.pdrUnitOfMeasurement',
    defaultMessage: 'Unit of Measure for Size of Space',
  },
  pdrUnitOfMeasurementPlaceholder: {
    id: 'groupBookings.pdrUnitOfMeasurementPlaceholder',
    defaultMessage: 'Square Footage',
  },
  pdrMinStandingCapacity: {
    id: 'groupBookings.pdrMinStandingCapacity',
    defaultMessage: 'Min Standing Capacity',
  },
  pdrMaxStandingCapacity: {
    id: 'groupBookings.pdrMaxStandingCapacity',
    defaultMessage: 'Max Standing Capacity',
  },
  pdrMinSeatedCapacity: {
    id: 'groupBookings.pdrMinSeatedCapacity',
    defaultMessage: 'Min Seated Capacity',
  },
  pdrMaxSeatedCapacity: {
    id: 'groupBookings.pdrMaxSeatedCapacity',
    defaultMessage: 'Max Seated Capacity',
  },
  pdrSquareFootage: {
    id: 'groupBookings.pdrSquareFootage',
    defaultMessage: 'Size of Space',
  },
  pdrSquareFootagePlaceholder: {
    id: 'groupBookings.pdrSquareFootagePlaceholder',
    defaultMessage: 'ex. 350',
  },
  pdrRoomType: {
    id: 'groupBookings.pdrRoomType',
    defaultMessage: 'Room Type',
  },
  pdrRoomTypePlaceholder: {
    id: 'groupBookings.pdrRoomTypePlaceholder',
    defaultMessage: 'Semi-Private',
  },
  pdrAmenities: {
    id: 'groupBookings.pdrAmenities',
    defaultMessage: 'Amenities',
  },
  pdrAmenitiesPlaceholder: {
    id: 'groupBookings.pdrAmenitiesPlaceholder',
    defaultMessage: 'Select Amenities',
  },
  menu: {
    id: 'groupBookings.menu',
    defaultMessage: 'Menu',
  },
  menuDescription: {
    id: 'groupBookings.menuDescription',
    defaultMessage:
      'The file name will be visible to guests, so use a name that represents what’s inside the file. Supported file types: JPG, PNG, doc, PDF, CSV',
  },
  settingsTabTitle: {
    id: 'groupBookings.settingsTabTitle',
    defaultMessage: 'Settings',
  },
  dineWiseTabTitle: {
    id: 'groupBookings.dineWiseTabTitle',
    defaultMessage: 'DineWise Team',
  },
  dineWiseOnlyTabWarning: {
    id: 'groupBookings.dineWiseOnlyTabWarning',
    defaultMessage: 'For DineWise Team use only',
  },
  dineWiseOnlyTabWarningDescription: {
    id: 'groupBookings.dineWiseOnlyTabWarningDescription',
    defaultMessage:
      'Your DineWise Team will complete these settings for you. Reach out to your DineWise account manager if you have questions.',
  },
} as const)
