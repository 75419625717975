import { type FormEvent, type PropsWithChildren, type ReactElement, useMemo, useState } from 'react'
import { useGetVenueLanguagesQuery, type LanguageCode, type LanguageListData } from '@sevenrooms/core/api'
import type { LanguageStrings, ReservationWidgetSettingsLanguageString } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Select } from '@sevenrooms/core/ui-kit/core'
import { TextArea } from '@sevenrooms/core/ui-kit/form'
import { Box, Spreadsheet, type DataTableColumn, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { otherLanguagesTableMessages } from './OtherLanguagesTable.locales'

export interface OtherLanguagesTableProps {
  languageStrings: LanguageStrings
  onChange: (value: string, language: LanguageCode, stringToChange: ReservationWidgetSettingsLanguageString) => void
  enablePrivateEventsBanner: boolean
}

interface OtherLanguagesTableColumn {
  id: ReservationWidgetSettingsLanguageString
  description: ReactElement
  defaultLanguage: string
  selectedLanguage?: string
}

const OtherLanguagesTableColumnWidth = 250

function DescriptionColumn({ primary, secondary }: { primary: string; secondary?: string }) {
  return (
    <VStack spacing="xs" mt="xs">
      <Text textStyle="body1Bold">{primary}</Text>
      {secondary && <Text>{secondary}</Text>}
    </VStack>
  )
}

function OtherLanguagesBaseColumn({
  children,
  'data-test': dataTest,
  removeRightPadding,
}: PropsWithChildren<{ 'data-test'?: string; removeRightPadding?: boolean }>) {
  return (
    <Box p={removeRightPadding ? 'm none m m' : 'm'} width="100%" data-test={dataTest}>
      {children}
    </Box>
  )
}

export function OtherLanguagesTable({ languageStrings, onChange, enablePrivateEventsBanner }: OtherLanguagesTableProps) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()
  const { data: languageListData } = useGetVenueLanguagesQuery({
    venueId,
  })
  const { defaultLanguage, enabledLanguages } = languageListData as LanguageListData

  const languageOptions = useMemo(
    () =>
      enabledLanguages.map(language => ({
        id: language.value as LanguageCode,
        label: language.name,
      })),
    [enabledLanguages]
  )

  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[0]?.id)

  const columns = useMemo<DataTableColumn<OtherLanguagesTableColumn>[]>(() => {
    const columns: DataTableColumn<OtherLanguagesTableColumn>[] = [
      {
        header: '',
        key: 'description',
        render: (value: OtherLanguagesTableColumn) => (
          <OtherLanguagesBaseColumn removeRightPadding data-test={`sr-${value.id}-description-column`}>
            {value.description}
          </OtherLanguagesBaseColumn>
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        alignItems: 'start',
        minWidth: OtherLanguagesTableColumnWidth,
        width: OtherLanguagesTableColumnWidth,
        backgroundColor: 'secondaryBackground',
        removePadding: true,
      },
      {
        header: () => <OtherLanguagesBaseColumn removeRightPadding>{defaultLanguage.name}</OtherLanguagesBaseColumn>,
        key: 'defaultLanguage',
        render: (value: OtherLanguagesTableColumn) => (
          <OtherLanguagesBaseColumn removeRightPadding={!!selectedLanguage} data-test={`sr-${value.id}-default-language-column`}>
            <TextArea
              data-test={`sr-${value.id}-default-language-text-area`}
              placeholder={formatMessage(otherLanguagesTableMessages.otherLanguagesTableColumnPlaceholder)}
              aria-label={formatMessage(otherLanguagesTableMessages.otherLanguagesTableColumnPlaceholder)}
              fullWidth
              resize="none"
              value={value.defaultLanguage}
              onChange={(e: FormEvent<HTMLTextAreaElement>) => {
                onChange(e.currentTarget.value, defaultLanguage.value, value.id)
              }}
            />
          </OtherLanguagesBaseColumn>
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        width: OtherLanguagesTableColumnWidth,
        minWidth: OtherLanguagesTableColumnWidth,
        removePadding: true,
      },
    ]
    if (selectedLanguage) {
      columns.push({
        header: () => (
          <OtherLanguagesBaseColumn>
            <Select
              options={languageOptions}
              value={selectedLanguage}
              onChange={value => setSelectedLanguage(value as LanguageCode)}
              searchable={false}
            />
          </OtherLanguagesBaseColumn>
        ),
        key: 'selectedLanguage',
        render: (value: OtherLanguagesTableColumn) => (
          <OtherLanguagesBaseColumn data-test={`sr-${value.id}-selected-language-column`}>
            <TextArea
              data-test={`sr-${value.id}-selected-language-text-area`}
              placeholder={formatMessage(otherLanguagesTableMessages.otherLanguagesTableColumnPlaceholder)}
              aria-label={formatMessage(otherLanguagesTableMessages.otherLanguagesTableColumnPlaceholder)}
              fullWidth
              resize="none"
              value={value.selectedLanguage || ''}
              onChange={(e: FormEvent<HTMLTextAreaElement>) => {
                onChange(e.currentTarget.value, selectedLanguage, value.id)
              }}
            />
          </OtherLanguagesBaseColumn>
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        width: OtherLanguagesTableColumnWidth,
        minWidth: OtherLanguagesTableColumnWidth,
        removePadding: true,
      })
    }
    return columns
  }, [languageOptions, defaultLanguage, formatMessage, onChange, selectedLanguage])

  const tableData = useMemo(() => {
    let result: OtherLanguagesTableColumn[] = [
      {
        id: 'resWidgetCrossSellVenueLongDescription',
        description: (
          <DescriptionColumn primary={formatMessage(otherLanguagesTableMessages.otherLanguagesTableVenueDescriptionColumnLabel)} />
        ),
        defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetCrossSellVenueLongDescription,
        selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetCrossSellVenueLongDescription : '',
      },
      {
        id: 'resWidgetTitle',
        description: <DescriptionColumn primary={formatMessage(otherLanguagesTableMessages.otherLanguagesTableWidgetTitleColumnLabel)} />,
        defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetTitle,
        selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetTitle : '',
      },
      {
        id: 'resWidgetSpecialAttentionLabel',
        description: (
          <DescriptionColumn
            primary={formatMessage(otherLanguagesTableMessages.otherLanguagesTableSpecialAttentionLabelColumnLabel)}
            secondary={formatMessage(otherLanguagesTableMessages.otherLanguagesTableSpecialAttentionLabelColumnDescription)}
          />
        ),
        defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetSpecialAttentionLabel,
        selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetSpecialAttentionLabel : '',
      },
      {
        id: 'resWidgetSpecialAttentionInfoBody',
        description: (
          <DescriptionColumn
            primary={formatMessage(otherLanguagesTableMessages.otherLanguagesTableSpecialAttentionBodyColumnLabel)}
            secondary={formatMessage(otherLanguagesTableMessages.otherLanguagesTableSpecialAttentionBodyColumnDescription)}
          />
        ),
        defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetSpecialAttentionInfoBody,
        selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetSpecialAttentionInfoBody : '',
      },
    ]
    if (enablePrivateEventsBanner) {
      result = [
        ...result,
        {
          id: 'resWidgetPrivateEventsBannerLabel',
          description: <DescriptionColumn primary={formatMessage(otherLanguagesTableMessages.otherLanguagesTablePEBannerHeadlineLabel)} />,
          defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetPrivateEventsBannerLabel,
          selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetPrivateEventsBannerLabel : '',
        },
        {
          id: 'resWidgetPrivateEventsBannerDescription',
          description: (
            <DescriptionColumn primary={formatMessage(otherLanguagesTableMessages.otherLanguagesTablePEBannerDescriptionLabel)} />
          ),
          defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetPrivateEventsBannerDescription,
          selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetPrivateEventsBannerDescription : '',
        },
        {
          id: 'resWidgetPrivateEventsBannerExploreButtonLabel',
          description: <DescriptionColumn primary={formatMessage(otherLanguagesTableMessages.otherLanguagesTablePEBannerButtonLabel)} />,
          defaultLanguage: languageStrings[defaultLanguage?.value].resWidgetPrivateEventsBannerExploreButtonLabel,
          selectedLanguage: selectedLanguage ? languageStrings[selectedLanguage]?.resWidgetPrivateEventsBannerExploreButtonLabel : '',
        },
      ]
    }
    return result
  }, [formatMessage, defaultLanguage, languageStrings, selectedLanguage, enablePrivateEventsBanner])

  return <Spreadsheet data={tableData} columns={columns} noPagination />
}
