import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  accountAndAuthentication: {
    id: 'paymentSettings.FreedomPay.accountAndAuthentication',
    defaultMessage: 'FreedomPay Account & Authentication',
  },
  esKey: {
    id: 'paymentSettings.FreedomPay.esKey',
    defaultMessage: 'ES Key',
  },
  storeId: {
    id: 'paymentSettings.FreedomPay.storeId',
    defaultMessage: 'Store ID',
  },
  terminalId: {
    id: 'paymentSettings.FreedomPay.terminalId',
    defaultMessage: 'Terminal ID',
  },
} as const)
