import { useMemo } from 'react'
import { type CreateEditExperienceApi, FileService } from '@sevenrooms/core/api'
import { ExperienceVisibilityEnum, type ImageObject } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useImageForm } from '@sevenrooms/core/ui-kit/form'
import { messages } from '@sevenrooms/mgr-marketing-templates-gallery/locales/marketingTemplatesGallery.locales'
import type { TemplateProps } from '../MarketingTemplatesGallery'

export const useCreateOfferFromTemplateForm = () => {
  const image = useImageForm()
  const { formatMessage } = useLocales()
  const fieldLengthError = formatMessage(messages.fieldLengthError, { minSize: 1 })
  return useMemo(
    () =>
      z.object({
        templateId: z.string(),
        name: z.string().trim().min(1, fieldLengthError),
        description: z.string().trim().min(1, fieldLengthError),
        previewDescription: z.string().trim(),
        price: z.string().trim().min(1, fieldLengthError),
        minPartySize: z.string().trim().min(1, fieldLengthError),
        maxPartySize: z.string().trim().min(1, fieldLengthError),
        heroImage: image,
        linkedAccessRuleIds: z.array(z.string()),
      }),
    [fieldLengthError, image]
  )
}
export type CreateOfferFromTemplateFormData = ZodSchema<typeof useCreateOfferFromTemplateForm>

export async function formToExperience({
  formData,
  onError,
}: {
  formData: CreateOfferFromTemplateFormData
  onError: () => void
}): Promise<CreateEditExperienceApi> {
  const heroImageUrlKey = async () => {
    try {
      const fileData = formData.heroImage?.fileData
      if (fileData) {
        const { urlKey: heroImageUrlKey } = await FileService.uploadFile({
          file: fileData,
          rurl: Math.random().toString(),
        })
        return heroImageUrlKey
      }
      return undefined
    } catch {
      onError()
      return undefined
    }
  }

  return {
    name: formData.name,
    description: formData.description || '',
    price_description: formData.price,
    template_id: 'default',
    title: formData.name,
    default_party_size: +formData.minPartySize,
    hero_image: formData.heroImage ? getImage(await heroImageUrlKey(), formData.heroImage) : {},
    image_list: [],
    visibility: ExperienceVisibilityEnum.PUBLIC,
    unlinked_access_rule_ids: [],
    linked_access_rule_ids: formData.linkedAccessRuleIds,
    unlinked_event_ids: [],
    linked_event_ids: [],
    offer_type: 'EXPERIENCE',
    status: 'ACTIVE',
    hide_on_directory: false,
  }
}

function getImage(url: string | undefined, formImage: ImageObject | null) {
  if (formImage) {
    const imageUrl = url || formImage.rawUrl
    return {
      crop_info: formImage.crop,
      name: formImage.name,
      raw_url_key: imageUrl,
      photo_dict: { large: imageUrl, small: imageUrl },
    }
  }
  return null
}

export function useDefaultValues(offerTemplate: TemplateProps, venueName: string) {
  const offerName = offerTemplate.offerName?.replace('(name)', venueName)
  const partySize = offerTemplate.partySize?.split(',')
  const defaultPartySize = '2'
  return useMemo(
    () => ({
      templateId: offerTemplate.id,
      name: offerName,
      description: offerTemplate.offerDescription,
      previewDescription: '',
      price: offerTemplate.paymentPolicy,
      minPartySize: partySize ? partySize[0]?.split(':')[1] : defaultPartySize,
      maxPartySize: partySize ? partySize[1]?.split(':')[1] : defaultPartySize,
      heroImage: offerTemplate.offerImage
        ? { rawUrl: offerTemplate.offerImage, name: offerTemplate?.offerImageFileName || ' ', crop: '' }
        : null,
      linkedAccessRuleIds: [],
    }),
    [
      offerName,
      offerTemplate.id,
      offerTemplate.offerDescription,
      offerTemplate.offerImage,
      offerTemplate?.offerImageFileName,
      offerTemplate.paymentPolicy,
      partySize,
    ]
  )
}
