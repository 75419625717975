import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

interface SettingValueProps {
  name: string
  title: string
}

export function SettingValue({ name, title }: SettingValueProps) {
  return (
    <VStack>
      <Text textStyle="body2Medium" color="secondaryFont">
        {title}
      </Text>
      <Text textStyle="body2" color="secondaryFont">
        {name}
      </Text>
    </VStack>
  )
}
