import type { ReservationWidgetSettingsV2 } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Checkbox, RadioGroupToggle, RadioGroup, FormInput, FormNumberInput } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, VStack, BaseSection } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text, Link } from '@sevenrooms/core/ui-kit/typography'
import { type NavigationPermissions, useNavigationPermissionsContext, useVenueContext } from '@sevenrooms/mgr-core'
import { checkoutConfirmationMessages } from './CheckoutConfirmation.locales'
import { UpgradesBanner } from './UpgradesBanner'
import type { CheckoutConfirmationForm } from '../ReservationSettings.zod'

export interface CheckoutConfirmationProps {
  field: Field<CheckoutConfirmationForm>
  data: ReservationWidgetSettingsV2
}

export function CheckoutConfirmation({ field, data }: CheckoutConfirmationProps) {
  const { formatMessage } = useLocales()
  const customCheckoutPolicyOn = useWatch(field.prop('customCheckoutPolicyOn'))
  const salutationTypeOn = useWatch(field.prop('salutationTypeOn'))
  const aboveAgeConsentOn = useWatch(field.prop('aboveAgeConsentOn'))
  const venueSmsMarketingOn = useWatch(field.prop('venueSmsMarketingOn'))
  const venueSpecificMarketingOn = useWatch(field.prop('venueSpecificMarketingOn'))
  const venueGroupMarketingOn = useWatch(field.prop('venueGroupMarketingOn'))
  const tailoredCommunicationOn = useWatch(field.prop('tailoredCommunicationOn'))
  const displayReservationSmsOptIn = useWatch(field.prop('displayReservationSmsOptIn'))
  const enableDietaryRestrictions = useWatch(field.prop('enableDietaryRestrictions'))
  const birthdayTypeOn = useWatch(field.prop('birthdayTypeOn'))
  const postalCodeTypeOn = useWatch(field.prop('postalCodeTypeOn'))
  const groupBookingsPolicyOn = useWatch(field.prop('groupBookingsPolicyOn'))
  const permissions: undefined | NavigationPermissions = useNavigationPermissionsContext()

  const { venue } = useVenueContext()

  const showDietaryGdprOptIn = data.checkoutConfirmation.requireDietaryTagGdprOptIn || permissions?.isSuperUser
  const showGiftCardRedemption = data.checkoutConfirmation.redemptionSystemsEnabled?.includes('VALUTEC')
  const newResWidgetPrivateEventsEnabled = !!data.general.newResWidgetPrivateEventsEnabled

  return (
    <VStack spacing="lm" mt="lm">
      <BaseSection title={formatMessage(checkoutConfirmationMessages.extrasLabel)} padding="xl">
        <VStack spacing="l" width="100%" mt="l" pl="xl" mb="xxl" maxWidth="50%">
          <Checkbox
            field={field.prop('enablePromoCodes')}
            data-test="enablePromoCodes"
            description={formatMessage(checkoutConfirmationMessages.promoCodesDescription, {
              link: (
                <Link isExternal to={`/manager2/${venue.urlKey}/marketing/promo-codes/`} target="_self">
                  {formatMessage(checkoutConfirmationMessages.promoCodesName)}
                  &nbsp;
                  <Icon name="VMSWeb-open-in-new" size="sm" />
                </Link>
              ),
            })}
          >
            <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.promoCodes)}</Text>
          </Checkbox>
          {showGiftCardRedemption && (
            <Checkbox field={field.prop('enableGiftCardRedemption')} data-test="enableGiftCardRedemption">
              <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.enableGiftCardRedemption)}</Text>
            </Checkbox>
          )}
          <Checkbox field={field.prop('enableSocialMediaLogin')} data-test="enableSocialMediaLogin">
            <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.enableSocialMediaLogin)}</Text>
          </Checkbox>
        </VStack>
      </BaseSection>
      {permissions?.canViewMarketing && (
        <BaseSection>
          <UpgradesBanner />
        </BaseSection>
      )}
      <BaseSection title={formatMessage(checkoutConfirmationMessages.optInsAndLegalese)} padding="xl">
        <VStack spacing="l" width="100%" mt="l" pl="xl" mb="xxl" maxWidth="50%">
          {newResWidgetPrivateEventsEnabled && (
            <Box>
              <Checkbox field={field.prop('groupBookingsPolicyOn')} data-test="groupBookingsPolicyOn">
                <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.groupBookingPolicyOn)}</Text>
              </Checkbox>
              {groupBookingsPolicyOn && (
                <Box pt="s" pl="lm">
                  <RadioGroupToggle
                    field={field.prop('isGroupBookingsPolicyDefaultOn')}
                    data-test="isGroupBookingsPolicyDefaultOn"
                    choices={[
                      { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                      { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                    ]}
                  />
                </Box>
              )}
            </Box>
          )}
          <Box>
            <Checkbox field={field.prop('customCheckoutPolicyOn')} data-test="customCheckoutPolicyOn">
              <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.customCheckoutPolicyOn)}</Text>
            </Checkbox>
            {customCheckoutPolicyOn && (
              <Box pt="s" pl="lm">
                <RadioGroupToggle
                  field={field.prop('isDefaultCustomCheckoutPolicyOn')}
                  data-test="isDefaultCustomCheckoutPolicyOn"
                  choices={[
                    { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                    { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                  ]}
                />
              </Box>
            )}
          </Box>
          <Box>
            <Checkbox field={field.prop('aboveAgeConsentOn')} data-test="aboveAgeConsentOn">
              <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.mandatedAgeConsent)}</Text>
            </Checkbox>
            {aboveAgeConsentOn && (
              <Box pt="s" pl="l" width="83px">
                <FormNumberInput
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  field={field.prop('ageToConsent')}
                  greaterThanZero
                  required
                  data-test="ageToConsent"
                />
              </Box>
            )}
          </Box>
          {data.checkoutConfirmation.isSmsMarketingEnabled && (
            <Box>
              <Checkbox field={field.prop('venueSmsMarketingOn')} data-test="venueSmsMarketingOn">
                <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.venueSmsMarketingOn)}</Text>
              </Checkbox>
              {venueSmsMarketingOn && (
                <Box pt="s" pl="lm">
                  <RadioGroupToggle
                    field={field.prop('isVenueSmsMarketingPolicyDefaultOn')}
                    data-test="isVenueSmsMarketingPolicyDefaultOn"
                    choices={[
                      { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                      { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                    ]}
                  />
                </Box>
              )}
            </Box>
          )}
          <Box>
            <Checkbox field={field.prop('venueSpecificMarketingOn')} data-test="venueSpecificMarketingOn">
              <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.venueSpecificMarketingOn)}</Text>
            </Checkbox>
            {venueSpecificMarketingOn && (
              <Box pt="s" pl="lm">
                <RadioGroupToggle
                  field={field.prop('isVenueSpecificMarketingPolicyDefaultOn')}
                  data-test="isVenueSmsMarketingPolicyDefaultOn"
                  choices={[
                    { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                    { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                  ]}
                />
              </Box>
            )}
          </Box>

          <Box>
            <Checkbox field={field.prop('venueGroupMarketingOn')} data-test="venueGroupMarketingOn">
              <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.venueGroupMarketingOn)}</Text>
            </Checkbox>
            {venueGroupMarketingOn && (
              <Box pt="s" pl="lm">
                <RadioGroupToggle
                  field={field.prop('isVenueGroupMarketingPolicyDefaultOn')}
                  data-test="isVenueGroupMarketingPolicyDefaultOn"
                  choices={[
                    { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                    { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                  ]}
                />
              </Box>
            )}
          </Box>

          <Box>
            <Checkbox
              field={field.prop('tailoredCommunicationOn')}
              data-test="tailoredCommunicationOn"
              disabled={data.checkoutConfirmation.isTailoredCommunicationPolicyDisabled}
              onClick={event => {
                if (event.currentTarget.checked) {
                  field.prop('isTailoredCommunicationPolicyDefaultOn').set(true)
                }
              }}
            >
              <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.tailoredCommunicationOn)}</Text>
            </Checkbox>
            {tailoredCommunicationOn && (
              <Box pt="s" pl="lm">
                <RadioGroupToggle
                  field={field.prop('isTailoredCommunicationPolicyDefaultOn')}
                  data-test="isTailoredCommunicationPolicyDefaultOn"
                  choices={[
                    { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                    { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                  ]}
                />
              </Box>
            )}
          </Box>

          {(data.checkoutConfirmation.remindersSmsEnabled || data.checkoutConfirmation.smsBookingNotificationEnabled) &&
            data.checkoutConfirmation.showSmsOptInSettingsForTwilioUs && (
              <Box>
                <Checkbox field={field.prop('displayReservationSmsOptIn')} data-test="displayReservationSmsOptIn" disabled>
                  <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.displayReservationSmsOptIn)}</Text>
                </Checkbox>
                {displayReservationSmsOptIn && (
                  <Box pt="s" pl="lm">
                    <RadioGroupToggle
                      field={field.prop('reservationSmsOptInOn')}
                      data-test="reservationSmsOptInOn"
                      choices={[
                        { label: formatMessage(checkoutConfirmationMessages.checkedByDefault), value: true },
                        { label: formatMessage(checkoutConfirmationMessages.uncheckedByDefault), value: false },
                      ]}
                    />
                  </Box>
                )}
              </Box>
            )}

          <Checkbox field={field.prop('recaptchaOn')} data-test="recaptchaOn">
            <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.recaptchaOn)}</Text>
          </Checkbox>

          <VStack spacing="xs">
            <Text fontSize="m" fontWeight="bold">
              {formatMessage(checkoutConfirmationMessages.privacyPolicyNotification)}
            </Text>
            <SecondaryText>
              {formatMessage(checkoutConfirmationMessages.privacyPolicyNotificationDescr, {
                link: (
                  <Link isExternal to={`/manager2/${venue.urlKey}/settings/widget_settings/`} target="_self">
                    {formatMessage(checkoutConfirmationMessages.privacyPolicyNotificationLink)}
                    &nbsp;
                    <Icon name="VMSWeb-open-in-new" size="sm" />
                  </Link>
                ),
              })}
            </SecondaryText>
          </VStack>

          <VStack spacing="lm">
            <VStack spacing="xs">
              <Text fontSize="m">{formatMessage(checkoutConfirmationMessages.customPrivacyPolicyLink)}</Text>
              <FormInput field={field.prop('textCustomPrivacyPolicyLink')} type="text" fullWidth />
            </VStack>

            <VStack spacing="xs">
              <Text fontSize="m">{formatMessage(checkoutConfirmationMessages.customPrivacyPolicyHolderName)}</Text>
              <FormInput field={field.prop('textCustomPolicyHolderName')} type="text" fullWidth />
            </VStack>

            <VStack spacing="xs">
              <Text fontSize="m">{formatMessage(checkoutConfirmationMessages.customGDPRPolicyLink)}</Text>
              <FormInput field={field.prop('textCustomGdprPolicyLink')} type="text" fullWidth />
            </VStack>
          </VStack>
        </VStack>
      </BaseSection>
      <BaseSection title={formatMessage(checkoutConfirmationMessages.clientInformation)} padding="xl">
        <VStack spacing="l" width="100%" mt="l" pl="xl" mb="xxl" maxWidth="50%">
          <Box>
            <Checkbox field={field.prop('salutationTypeOn')} data-test="salutationTypeOn">
              <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.salutationType)}</Text>
            </Checkbox>
            {salutationTypeOn && (
              <Box pt="s" pl="lm">
                <RadioGroup
                  field={field.prop('salutationType')}
                  data-test="salutationType"
                  choices={[
                    { label: formatMessage(checkoutConfirmationMessages.required), value: 'Required' },
                    { label: formatMessage(checkoutConfirmationMessages.optional), value: 'Optional' },
                  ]}
                />
              </Box>
            )}
          </Box>
          <Box>
            <Checkbox field={field.prop('birthdayTypeOn')} data-test="birthdayTypeOn">
              <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.birthdayType)}</Text>
            </Checkbox>
            {birthdayTypeOn && (
              <Box pt="s" pl="lm">
                <RadioGroup
                  field={field.prop('birthdayType')}
                  data-test="birthdayType"
                  choices={[
                    { label: formatMessage(checkoutConfirmationMessages.required), value: 'Required' },
                    { label: formatMessage(checkoutConfirmationMessages.optional), value: 'Optional' },
                  ]}
                />
              </Box>
            )}
          </Box>

          <Box>
            <Checkbox field={field.prop('postalCodeTypeOn')} data-test="birthdayTypeOn">
              <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.postalCode)}</Text>
            </Checkbox>
            {postalCodeTypeOn && (
              <Box pt="s" pl="lm">
                <RadioGroup
                  field={field.prop('postalCodeType')}
                  data-test="postalCodeType"
                  choices={[
                    { label: formatMessage(checkoutConfirmationMessages.required), value: 'Required' },
                    { label: formatMessage(checkoutConfirmationMessages.optional), value: 'Optional' },
                  ]}
                />
              </Box>
            )}
          </Box>

          {showDietaryGdprOptIn && (
            <Box>
              <Checkbox field={field.prop('enableDietaryRestrictionsConfirmation')} data-test="enableDietaryRestrictionsConfirmation">
                <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.enableDietaryRestrictionsConfirmation)}</Text>
              </Checkbox>
              {enableDietaryRestrictions && (
                <Box pt="s" pl="lm">
                  <Checkbox field={field.prop('requireDietaryTagGdprOptInConfirmation')} data-test="requireDietaryTagGdprOptInConfirmation">
                    {formatMessage(checkoutConfirmationMessages.requireDietaryTagGdprOptInConfirmation)}
                  </Checkbox>
                </Box>
              )}
            </Box>
          )}

          <Checkbox
            field={field.prop('enableSpecialOccasionsConfirmation')}
            data-test="enableSpecialOccasionsConfirmation"
            description={formatMessage(checkoutConfirmationMessages.enableSpecialOccasionsDescrConfirmation, {
              link: (
                <Link isExternal to={`/manager/${venue.urlKey}/manage/tags`}>
                  {formatMessage(checkoutConfirmationMessages.clientTags)}
                  &nbsp;
                  <Icon name="VMSWeb-open-in-new" size="sm" />
                </Link>
              ),
            })}
          >
            <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.enableSpecialOccasionsConfirmation)}</Text>
          </Checkbox>

          <Checkbox
            field={field.prop('enableAdditionalProfileInfoConfirmation')}
            data-test="enableAdditionalProfileInfoConfirmation"
            description={formatMessage(checkoutConfirmationMessages.enableAdditionalProfileInfoDescConfirmation)}
          >
            <Text fontWeight="bold">{formatMessage(checkoutConfirmationMessages.enableAdditionalProfileInfoConfirmation)}</Text>
          </Checkbox>
        </VStack>
      </BaseSection>
    </VStack>
  )
}
