import { useEffect, useState } from 'react'
import type { UseForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { PreviewImage, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import AvailabilityStepImage from '@sevenrooms/mgr-marketing-templates-gallery/assets/stepper-flow/images/availability-step.svg'
import { messages } from '@sevenrooms/mgr-marketing-templates-gallery/locales/offerTemplates.locales'
import { Button } from '@sevenrooms/react-components/components/Button'
import { LinkToExistingAccessRule } from './LinkToExistingAccessRule'
import type { CreateOfferFromTemplateFormData } from './CreateOfferFromTemplateForm.zod'

interface AvailabilityProps {
  form: UseForm<z.ZodType<CreateOfferFromTemplateFormData>>
  venueId: string
  setShowFooterMenu: (showMenu: boolean) => void
  setFlowCompleted: (isCompleted: boolean) => void
}

export function Availability({ form, venueId, setShowFooterMenu, setFlowCompleted }: AvailabilityProps) {
  const [selectedAvailabilityOption, setSelectedAvailabilityOption] = useState('')
  const { getValues } = form
  const values = getValues()

  useEffect(() => {
    setShowFooterMenu(!!selectedAvailabilityOption)
    if (selectedAvailabilityOption === 'linkToExistingAccessRule') {
      setFlowCompleted(true)
    } else {
      setFlowCompleted(false)
    }
  }, [selectedAvailabilityOption, setFlowCompleted, setShowFooterMenu])

  const onLinkToExistingAccessRule = () => {
    setSelectedAvailabilityOption('linkToExistingAccessRule')
  }

  const onCreateNewAccessRule = () => {}

  return (
    <VStack pt="lm" pb="lm" spacing="lm" width="496px" alignItems="center">
      {!selectedAvailabilityOption ? (
        <AvailabilityOptions
          offerName={values.name}
          onLinkToExistingAccessRule={onLinkToExistingAccessRule}
          onCreateNewAccessRule={onCreateNewAccessRule}
        />
      ) : (
        <LinkToExistingAccessRule form={form} venueId={venueId} />
      )}
    </VStack>
  )
}

function AvailabilityOptions({
  offerName,
  onLinkToExistingAccessRule,
  onCreateNewAccessRule,
}: {
  offerName: string
  onLinkToExistingAccessRule: () => void
  onCreateNewAccessRule: () => void
}) {
  const { formatMessage } = useLocales()

  return (
    <>
      <PreviewImage src={AvailabilityStepImage} maxWidth="401px" alt="icon" data-test="availability-step-image" />
      <VStack spacing="xs">
        <Text textStyle="h1" fontWeight="bold" textAlign="center">
          {formatMessage(messages.availabilityStepTitle, {
            offerName: (
              <Text color="primarySelected" textStyle="h1" fontWeight="bold">
                {offerName}
              </Text>
            ),
          })}
        </Text>
        <Text textStyle="body1" textAlign="center">
          {formatMessage(messages.availabilityStepDescription, { offerName })}
        </Text>
      </VStack>
      <VStack spacing="s">
        <Button
          data-test="create-new-access-rule-btn"
          variant="contained"
          size="medium"
          sx={{ width: '420px' }}
          onClick={onCreateNewAccessRule}
        >
          {formatMessage(messages.createNewAccessRuleButtonText)}
        </Button>
        <Button
          data-test="link-existing-access-rule-btn"
          variant="outlined"
          size="medium"
          sx={{ width: '420px' }}
          onClick={onLinkToExistingAccessRule}
        >
          {formatMessage(messages.linkToExistingAccessRuleButtonText)}
        </Button>
      </VStack>
    </>
  )
}
