import ExperienceTemplates from '../../assets/experiences/config.json'
import type { TemplateProps } from './MarketingTemplatesGallery'

export function GetTemplateImages() {
  function importAll(r: __WebpackModuleApi.RequireContext) {
    const images = {}
    r.keys().map(item => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      images[item.replace('./', '')] = r(item)
      return images
    })
    return images
  }
  const allImages = importAll(
    require.context('@sevenrooms/mgr-marketing-templates-gallery/assets/experiences/images', false, /\.(png|jpe?g|svg)$/)
  )
  return allImages
}

export function GetTemplateDataByTemplateId(templateId: string) {
  const templates: TemplateProps[] = ExperienceTemplates
  const templateImages = GetTemplateImages()
  for (const template of templates) {
    if (template?.id === templateId) {
      return {
        ...template,
        offerImage: templateImages[template?.offerImage as keyof typeof templateImages],
        offerImageFileName: template?.offerImage,
      }
    }
  }
  return undefined
}
