import { Box } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import type { TemplateProps } from '@sevenrooms/mgr-marketing-templates-gallery'
import { GetTemplateDataByTemplateId } from '@sevenrooms/mgr-marketing-templates-gallery/components/MarketingTemplatesGallery/utils'
import { OffersStepperFlow } from '@sevenrooms/mgr-marketing-templates-gallery/components/OffersStepperFlow'

interface CreateExperienceFromTemplateParams {
  templateId: string
  venueKey: string
}

interface CreateExperienceFromTemplateProps {
  match: { params: CreateExperienceFromTemplateParams }
}

export function CreateExperienceFromTemplate({ match }: CreateExperienceFromTemplateProps) {
  const { templateId, venueKey } = match.params
  const templateData: TemplateProps | undefined = GetTemplateDataByTemplateId(templateId)
  const { venueId, venueName } = useVenueContext()

  return templateData ? (
    <Box
      pt="m"
      pb="m"
      borderWidth="s"
      borderBottomColor="borders"
      backgroundColor="primaryBackground"
      data-test="create-experience-from-template-page"
      height="100%"
    >
      <OffersStepperFlow templateData={templateData} venueKey={venueKey} venueName={venueName} venueId={venueId} />
    </Box>
  ) : (
    <></>
  )
}
